import { useEffect, useRef, useCallback } from 'react';
import { Map } from 'ol';
import { Brush } from '../types';
import { Polygon } from 'ol/geom';
import WebGLTileLayer from 'ol/layer/WebGLTile';
import { TypedArray } from '../types';

interface WebGLDrawingLayerProps {
  map: Map;
  currentBrush: Brush | null;
  brushSize: number;
  selectedArea: Polygon | null;
  drawOnCanvas: (coordinate: number[], brushSize: number) => void;
}

export function WebGLDrawingLayer({ map, currentBrush, brushSize, selectedArea, drawOnCanvas }: WebGLDrawingLayerProps) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const isDrawing = useRef(false);
  const lastPoint = useRef<number[] | null>(null);
  const drawingsRef = useRef<Array<{
    coordinate: number[];
    brush: Brush;
    size: number;
  }>>([]);

  // Draw a single stroke on the canvas
  const drawStroke = useCallback((ctx: CanvasRenderingContext2D, coordinate: number[], brush: Brush, size: number) => {
    const pixel = map.getPixelFromCoordinate(coordinate);
    if (!pixel) return;

    ctx.fillStyle = brush.color || 'rgba(0, 0, 0, 0.6)';
    ctx.beginPath();
    ctx.arc(pixel[0], pixel[1], size, 0, Math.PI * 2);
    ctx.fill();
  }, [map]);

  // Linear interpolation between two points
  const _interpolatePoints = useCallback((start: number[], end: number[], spacing: number) => {
    const points: number[][] = [];
    const dx = end[0] - start[0];
    const dy = end[1] - start[1];
    const distance = Math.sqrt(dx * dx + dy * dy);
    const steps = Math.max(Math.floor(distance / spacing), 1);
    
    for (let i = 0; i <= steps; i++) {
      const t = i / steps;
      points.push([
        start[0] + dx * t,
        start[1] + dy * t
      ]);
    }
    
    return points;
  }, []);

  // Handle drawing
  const handleDrawing = useCallback((event: MouseEvent) => {
    if (!isDrawing.current || !currentBrush || !selectedArea) return;

    const pixel = map.getEventPixel(event);
    const coordinate = map.getCoordinateFromPixel(pixel);

    if (!selectedArea.intersectsCoordinate(coordinate)) {
      lastPoint.current = null;
      return;
    }

    // If it's an eraser, allow drawing without mask check
    if (currentBrush.name === 'eraser') {
      drawOnCanvas(coordinate, Math.max(1, brushSize));
      lastPoint.current = coordinate;
      return;
    }

    // Check mask layer
    const maskLayer = map.getLayers().getArray()
      .find(layer => 
        layer instanceof WebGLTileLayer && 
        layer.get('purpose') === 'mask'
      ) as WebGLTileLayer;

    if (maskLayer) {
      // Get the pixel data from the mask layer
      const data = maskLayer.getData(pixel);
      
      if (data) {
        const brushIndex = currentBrush.index ?? 0;
        
        // Check if the data is a valid TypedArray
        if (data instanceof Uint8Array || 
            data instanceof Uint8ClampedArray || 
            data instanceof Float32Array) {
          
          // Only allow drawing if the mask value is 0 (valid area)
          if (data[brushIndex] === 0) {
            drawOnCanvas(coordinate, Math.max(1, brushSize));
            lastPoint.current = coordinate;
          } else {
            // Reset lastPoint if we hit a masked area
            lastPoint.current = null;
          }
        }
      }
    } else {
      // If no mask layer is present, don't allow drawing
      lastPoint.current = null;
    }
  }, [map, currentBrush, brushSize, selectedArea, drawOnCanvas]);

  // Reset lastPoint when drawing stops
  const handleMouseUp = useCallback(() => {
    isDrawing.current = false;
    lastPoint.current = null;
  }, []);

  // Redraw all stored drawings
  const redrawCanvas = useCallback(() => {
    if (!canvasRef.current) return;
    const ctx = canvasRef.current.getContext('2d');
    if (!ctx) return;

    const size = map.getSize();
    if (!size) return;

    // Clear canvas
    ctx.clearRect(0, 0, size[0], size[1]);

    // Redraw all stored drawings
    drawingsRef.current.forEach(drawing => {
      drawStroke(ctx, drawing.coordinate, drawing.brush, drawing.size);
    });
  }, [map, drawStroke]);

  // Set up canvas and event listeners
  useEffect(() => {
    if (!map || !canvasRef.current) return;

    const canvas = canvasRef.current;
    const size = map.getSize();
    if (!size) return;

    // Set up canvas
    canvas.width = size[0];
    canvas.height = size[1];
    canvas.style.position = 'absolute';
    canvas.style.top = '0';
    canvas.style.left = '0';
    canvas.style.pointerEvents = 'none';
    canvas.style.zIndex = '40';

    const mapViewport = map.getViewport();
    mapViewport.appendChild(canvas);

    // Update on every render frame instead of just moveend
    const handleMapRender = () => {
      requestAnimationFrame(redrawCanvas);
    };

    // Listen to postrender instead of moveend
    map.on('postrender', handleMapRender);

    return () => {
      if (canvas.parentNode === mapViewport) {
        mapViewport.removeChild(canvas);
      }
      map.un('postrender', handleMapRender);
    };
  }, [map, redrawCanvas]);

  // Update mouse event listeners
  useEffect(() => {
    if (!map) return;

    const mapElement = map.getTargetElement();

    const handleMouseDown = (e: MouseEvent) => {
      if (currentBrush) {
        isDrawing.current = true;
        lastPoint.current = null;  // Reset last point on new stroke
        handleDrawing(e);
      }
    };

    const handleMouseMove = (e: MouseEvent) => {
      if (isDrawing.current && currentBrush) {
        handleDrawing(e);
      }
    };

    mapElement.addEventListener('mousedown', handleMouseDown);
    mapElement.addEventListener('mousemove', handleMouseMove);
    mapElement.addEventListener('mouseup', handleMouseUp);
    mapElement.addEventListener('mouseleave', handleMouseUp);

    return () => {
      mapElement.removeEventListener('mousedown', handleMouseDown);
      mapElement.removeEventListener('mousemove', handleMouseMove);
      mapElement.removeEventListener('mouseup', handleMouseUp);
      mapElement.removeEventListener('mouseleave', handleMouseUp);
    };
  }, [map, handleDrawing, currentBrush, handleMouseUp]);

  return <canvas ref={canvasRef} />;
}