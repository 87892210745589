import React, { useEffect, useRef, useState, useCallback } from 'react';
import Map from 'ol/Map';
import View from 'ol/View';
import WebGLTileLayer from 'ol/layer/WebGLTile';
import VectorTileLayer from 'ol/layer/VectorTile';
import VectorTileSource from 'ol/source/VectorTile';
import MVT from 'ol/format/MVT';
import GeoTIFF from 'ol/source/GeoTIFF';
import { fromLonLat } from 'ol/proj';
import { Style, Fill, Stroke } from 'ol/style';
import { FeatureLike } from 'ol/Feature';
import { StyleFunction } from 'ol/style/Style';
import { CONFIG } from '../config';
import { DragPan } from 'ol/interaction';
import { WebGLDrawingLayer } from './WebGLDrawingLayer';
import { MaskLayer } from './MaskLayer';
import { Polygon, MultiPolygon, Circle, Point, LineString, MultiPoint, MultiLineString } from 'ol/geom';
import { Brush } from '../types';
import { MapBrowserEvent } from 'ol';
import { getAssetUrl } from '../utilities';
import { getCategoryFile } from '../utilities';
import { CATEGORIES } from '../constants';
import { PMTiles } from 'pmtiles';
import VectorTile from 'ol/VectorTile';
import RenderFeature from 'ol/render/Feature';
import { Feature } from 'ol';
import { Geometry } from 'ol/geom';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import { Draw } from 'ol/interaction';
import { DrawEvent } from 'ol/interaction/Draw';
import BaseEvent from 'ol/events/Event';

interface MapCoreProps {
  selectedCategory: string;
  selectedBands: number[];
  onMapReady: (map: Map) => void;
  currentBrush: Brush | null;
  brushSize: number;
  selectedArea: Polygon | null;
  drawOnCanvas: (coordinate: number[], brushSize: number) => void;
  onHover?: (coordinate: number[]) => void;
  children?: React.ReactNode;
  setSelectedArea: (polygon: Polygon | null) => void;
  setActiveSelection: (active: boolean) => void;
  isSelectionMode: boolean;
}

export function MapCore({ 
  selectedCategory, 
  selectedBands, 
  onMapReady, 
  children, 
  currentBrush, 
  brushSize, 
  selectedArea, 
  drawOnCanvas, 
  onHover,
  setSelectedArea,
  setActiveSelection,
  isSelectionMode 
}: MapCoreProps) {
  const mapRef = useRef<HTMLDivElement>(null);
  const mapInstanceRef = useRef<Map | null>(null);
  const selectedFeatureRef = useRef<Feature<Geometry> | null>(null);
  const hoveredFeatureRef = useRef<Feature<Geometry> | null>(null);
  const highlightLayerRef = useRef<VectorLayer<VectorSource<Feature<Geometry>>> | null>(null);
  const justFinishedDrawingRef = useRef(false);
  const isDrawingRef = useRef(false);

  // Create style state
  const [currentStyle, setCurrentStyle] = useState<Style | StyleFunction | undefined>(undefined);

  // Effect to handle style changes based on band selection
  useEffect(() => {
    if (!mapInstanceRef.current) return;

    const vectorLayer = mapInstanceRef.current.getLayers().getArray().find(
      layer => layer instanceof VectorTileLayer
    ) as VectorTileLayer;

    if (!vectorLayer) return;

    if (selectedBands.length === 0) {
      // When no bands are selected, use the OSMM style
      const osmStyle = (feature: FeatureLike) => {
        const styleCode = feature.get('style_code');
        
        // Default style (white fill, black outline)
        let fillColor = 'rgba(255, 255, 255, 1)';
        
        // Match style codes to Mapbox colors
        switch (styleCode) {
          // Water features
          case 40: // inland water
          case 11: // canal
          case 47: // tidal water
            fillColor = 'rgba(190, 255, 255, 1)';
            break;

          // Buildings and structures
          case 34: // building
            fillColor = 'rgba(255, 220, 175, 1)';
            break;
          case 44: // structure
            fillColor = 'rgba(255, 215, 195, 1)';
            break;
          case 45: // glasshouse
            fillColor = 'rgba(255, 204, 153, 1)';
            break;

          // Roads and paths
          case 37: // road or track
          case 9:  // track
          case 41: // path
            fillColor = 'rgba(215, 215, 215, 1)';
            break;

          // Natural features
          case 23: // rough grassland
          case 19: // scrub
          case 13: // mixed woodland
          case 14: // nonconiferous trees
          case 15: // coniferous trees
            fillColor = 'rgba(220, 255, 190, 1)';
            break;

          // Agricultural
          case 16: // agricultural land
            fillColor = 'rgba(194, 237, 161, 1)';
            break;

          // Natural ground
          case 35: // natural
            fillColor = 'rgba(210, 255, 180, 1)';
            break;

          // Manmade ground
          case 36: // manmade
            fillColor = 'rgba(210, 210, 170, 1)';
            break;
        }

        return new Style({
          fill: new Fill({
            color: fillColor
          }),
          stroke: new Stroke({
            color: 'rgba(0, 0, 0, 1)',
            width: 1
          })
        });
      };

      vectorLayer.setStyle(osmStyle);
      vectorLayer.setOpacity(1);
    } else {
      // When bands are selected, use the line-only style
      vectorLayer.setStyle(createVectorStyle());
      vectorLayer.setOpacity(0.5);
    }
  }, [selectedBands]);

  // Helper function to create the color expression
  const createColorExpression = (bands: number[]) => {
    if (bands.length === 0) return ['array', 0, 0, 0, 0];
    if (bands.length === 1) {
      return [
        'array',
        ['clamp', ['*', 0.2, ['band', bands[0]]], 0, 1],    // Less red
        ['clamp', ['*', 0.8, ['band', bands[0]]], 0, 1],    // More green
        ['clamp', ['*', 0.2, ['band', bands[0]]], 0, 1],    // Less blue
        ['clamp', ['*', 0.3, ['band', bands[0]]], 0, 0.7]   // Alpha based on band value
      ];
    }
    
    // For multiple bands, add the values to create cumulative green shades
    const bandValues = bands.map(b => ['band', b]);
    
    // Calculate cumulative effect for each color channel
    const bandSum = ['+', ...bandValues];
    const bandProductR = ['*', 0.2, ['/', bandSum, bands.length]];  // Average then apply color
    const bandProductG = ['*', 0.8, ['/', bandSum, bands.length]];  // Average then apply color
    const bandProductB = ['*', 0.2, ['/', bandSum, bands.length]];  // Average then apply color
    
    // Calculate cumulative alpha, allowing it to stack
    const alphas = bands.map(b => ['*', 0.3, ['band', b]]);
    const combinedAlpha = ['+', ...alphas];

    return [
      'array',
      ['clamp', bandProductR, 0, 1],    // Less red
      ['clamp', bandProductG, 0, 1],    // More green
      ['clamp', bandProductB, 0, 1],    // Less blue
      ['clamp', combinedAlpha, 0, 0.7]  // Cumulative alpha
    ];
  };

  // Initial map setup
  useEffect(() => {
    if (mapRef.current && !mapInstanceRef.current) {
      const bristolCoords = fromLonLat([-2.587910, 51.456314]);
      
      const pmtilesUrl = CONFIG.isProduction 
        ? `${CONFIG.apiUrl}/vector/bristol.pmtiles`
        : `/bucket/vector/bristol.pmtiles`;
      
      console.log('[PMTiles] Initializing with URL:', pmtilesUrl);
      
      const p = new PMTiles(pmtilesUrl);

      // Test a specific tile to verify connection
      const testZ = 12;
      const testX = 2018;
      const testY = 1362;
      
      p.getZxy(testZ, testX, testY).then(response => {
        console.log(`[PMTiles] Test tile z:${testZ} x:${testX} y:${testY} response:`, response);
      }).catch(error => {
        console.error(`[PMTiles] Error loading test tile:`, error);
      });

      const vectorLayer = new VectorTileLayer({
        source: new VectorTileSource({
          format: new MVT(),
          maxZoom: 24,
          minZoom: 10,
          tileSize: 512,
          tileUrlFunction: (tileCoord) => {
            // This ensures we generate a URL for each tile request
            if (!tileCoord) return '';
            const [z, x, y] = tileCoord;
            console.log(`[PMTiles] Generating URL for tile z:${z} x:${x} y:${y}`);
            return pmtilesUrl;  // Return the PMTiles URL for each tile
          },
          tileLoadFunction: async (tile, url) => {
            const vectorTile = tile as VectorTile<RenderFeature>;
            const coord = vectorTile.getTileCoord();
            const [z, x, y] = coord;
            
            console.log(`[PMTiles] Starting tile load for z:${z} x:${x} y:${y}`);
            
            try {
              const response = await p.getZxy(z, x, y);
              
              if (response && response.data) {
                console.log(`[PMTiles] Data received for z:${z} x:${x} y:${y}, size: ${response.data.byteLength} bytes`);
                try {
                  const features = vectorTile.getFormat().readFeatures(response.data, {
                    extent: vectorTile.extent,
                    featureProjection: vectorTile.projection
                  });
                  console.log(`[PMTiles] Features loaded for z:${z} x:${x} y:${y}:`, features.length);
                  vectorTile.setFeatures(features as RenderFeature[]);
                } catch (parseError) {
                  console.error(`[PMTiles] Error parsing features for z:${z} x:${x} y:${y}:`, parseError);
                  console.error('Parse error details:', parseError);
                }
              } else {
                console.warn(`[PMTiles] No data received for z:${z} x:${x} y:${y}`);
              }
            } catch (error) {
              console.error(`[PMTiles] Error loading tile z:${z} x:${x} y:${y}:`, error);
            }
          }
        }),
        style: createVectorStyle(),
        zIndex: 20,
        visible: true,
        renderBuffer: 200,
        declutter: true
      });

      // Force immediate rendering
      vectorLayer.setVisible(true);

      // Add source event listeners
      vectorLayer.getSource()?.on('tileloadstart', (event) => {
        console.log('[PMTiles] Source tileloadstart:', event.tile.getTileCoord());
      });

      vectorLayer.getSource()?.on('tileloadend', (event) => {
        console.log('[PMTiles] Source tileloadend:', event.tile.getTileCoord());
      });

      vectorLayer.getSource()?.on('tileloaderror', (event) => {
        console.error('[PMTiles] Source tileloaderror:', event.tile.getTileCoord());
      });

      const lidarLayer = new WebGLTileLayer({
        source: new GeoTIFF({
          sources: [{
            url: CONFIG.isProduction 
              ? `${CONFIG.apiUrl}/lidar/bristol_lidar_cog.tif`
              : `/bucket/lidar/bristol_lidar_cog.tif`,
          }],
          normalize: false,
          interpolate: true,
        }),
        style: {
          color: [
            'interpolate',
            ['linear'],
            ['band', 1],
            0, [255, 255, 255, 1],
            1, [50, 50, 50, 1],
            128, [150, 150, 150, 1],
            255, [250, 250, 250, 1]
          ]
        },
        opacity: 0.5,
        zIndex: 10,
        visible: false,
        properties: {
          purpose: 'lidar'
        }
      });

      // Add source loading event handlers for debugging
      const lidarSource = lidarLayer.getSource();
      if (lidarSource) {
        lidarSource.on('tileloadstart', (event: any) => {
        });

        lidarSource.on('tileloadend', (event: any) => {
        });

        lidarSource.on('tileloaderror', (error: any) => {
          console.error('[LIDAR] Tile load error:', error);
        });
      }

      // First location - initial COG layer creation
      const cogLayer = new WebGLTileLayer({
        source: new GeoTIFF({
          sources: [
            {
              url: `${CONFIG.apiUrl}/base/${selectedCategory}_base.tif`,
            },
          ],
        }),
        style: {
          color: createColorExpression(selectedBands)
        },
        visible: selectedBands.length > 0,
        zIndex: 30,
        opacity: 0.5,
      });

      // Create modification layer for drawn interventions
      const modificationLayer = new VectorLayer({
        source: new VectorSource(),
        style: new Style({
          fill: new Fill({
            color: 'rgba(255, 0, 0, 0.4)'
          }),
          stroke: new Stroke({
            color: 'rgba(0, 0, 0, 0.8)',
            width: 1
          })
        }),
        properties: {
          purpose: 'modifications',
          snapToGrid: true,
          gridSize: 1 // 1 meter grid
        },
        updateWhileAnimating: false, // Prevent updates during pan/zoom
        updateWhileInteracting: false, // Prevent updates during interactions
        zIndex: 50
      });

      // Create the map with explicit layer ordering
      const map = new Map({
        target: mapRef.current,
        layers: [
          lidarLayer,     // Base layer (zIndex: 10)
          vectorLayer,    // Vector features (zIndex: 20)
          cogLayer,       // COG layer (zIndex: 30)
          modificationLayer,
        ],
        view: new View({
          projection: 'EPSG:3857',
          center: bristolCoords,
          zoom: 12,  // Start at a middle zoom level
          maxZoom: 24,  // Changed from 16 to 24
          minZoom: 10,  // Match PMTiles min zoom
          constrainResolution: true
        }),
      });

      // Add this after creating the map
      let hasZoomed = false;
      map.once('postrender', () => {
        if (!hasZoomed) {
          hasZoomed = true;
          const view = map.getView();
          view.animate({
            zoom: 17,
            duration: 1000  // 1 second animation
          });
        }
      });

      // Log when the map is ready
      map.once('postrender', () => {
        console.log('[Map] Initial render complete');
        console.log('[Map] Current zoom:', map.getView().getZoom());
        console.log('[Map] Current center:', map.getView().getCenter());
      });

      mapInstanceRef.current = map;
      onMapReady(map);

      // Create highlight layer with more explicit styling
      const highlightLayer = new VectorLayer<VectorSource<Feature<Geometry>>>({
        source: new VectorSource<Feature<Geometry>>(),
        style: new Style({
          stroke: new Stroke({
            color: '#FF0000',
            width: 4,
            lineDash: [10, 10],
          }),
          fill: new Fill({
            color: 'rgba(255, 0, 0, 0.2)'
          }),
          zIndex: 9999
        }),
        opacity: 1,
        visible: true,
        zIndex: 9999,
        properties: {
          purpose: 'highlight'
        }
      });

      map.addLayer(highlightLayer);
      highlightLayerRef.current = highlightLayer;

      mapInstanceRef.current = map;
      onMapReady(map);

      map.on('click', (event) => {
        // Check if any draw interactions are active on the map
        const hasActiveDrawInteraction = map.getInteractions().getArray().some(interaction => 
          interaction instanceof Draw && interaction.getActive()
        );

        // If we're in draw mode or just finished drawing, don't do feature selection
        if (currentBrush || hasActiveDrawInteraction || justFinishedDrawingRef.current || isDrawingRef.current) {
          event.preventDefault();
          event.stopPropagation();
          return;
        }

        // Get features at click location
        const features = map.getFeaturesAtPixel(event.pixel, {
          hitTolerance: 5,
          layerFilter: (layer) => layer instanceof VectorTileLayer
        });

        if (features && features.length > 0) {
          // Prevent the event from triggering other handlers
          event.preventDefault();
          event.stopPropagation();

          const renderFeature = features[0] as RenderFeature;
          const flatCoordinates = renderFeature.getFlatCoordinates();
          const ends = renderFeature.getEnds();
          
          if (flatCoordinates && ends) {
            // Clear any existing selection mask layers
            map.getLayers().getArray()
              .filter(layer => layer.get('purpose') === 'selection-mask')
              .forEach(layer => map.removeLayer(layer));

            const coordinates: number[][] = [];
            
            for (let i = 0; i < ends[0]; i += 2) {
              coordinates.push([
                flatCoordinates[i],
                flatCoordinates[i + 1]
              ]);
            }
            
            // Close the ring if needed
            if (coordinates.length > 0 && 
                (coordinates[0][0] !== coordinates[coordinates.length - 1][0] ||
                 coordinates[0][1] !== coordinates[coordinates.length - 1][1])) {
              coordinates.push([...coordinates[0]]);
            }

            const polygon = new Polygon([coordinates]);
            
            // Create the mask layer with hole
            const largeExtent = [
              [-1e7, -1e7],
              [1e7, -1e7],
              [1e7, 1e7],
              [-1e7, 1e7],
              [-1e7, -1e7]
            ];

            const polygonWithHole = new Polygon([largeExtent, coordinates]);
            const maskLayer = new VectorLayer({
              source: new VectorSource({
                features: [new Feature({
                  geometry: polygonWithHole
                })]
              }),
              style: new Style({
                fill: new Fill({
                  color: 'rgba(255, 253, 245, 0.5)'
                }),
                stroke: new Stroke({
                  color: 'rgba(255, 0, 0, 1)',
                  width: 2
                })
              }),
              zIndex: 100,
              properties: {
                purpose: 'selection-mask'
              }
            });

            map.addLayer(maskLayer);
            setSelectedArea(polygon);
            setActiveSelection(true);
          }
        }
      });

      map.on('pointermove', (event) => {
        // Check if any draw interactions are active
        const hasActiveDrawInteraction = map.getInteractions().getArray().some(interaction => 
          interaction instanceof Draw && interaction.getActive()
        );

        // Don't show pointer cursor if in draw mode OR if there's an active draw interaction
        if (currentBrush || hasActiveDrawInteraction) return;

        const pixel = map.getEventPixel(event.originalEvent);
        const hit = map.hasFeatureAtPixel(pixel, {
          layerFilter: (layer) => layer instanceof VectorTileLayer
        });

        map.getTargetElement().style.cursor = hit ? 'pointer' : '';
      });

      // Add this after creating the map
      map.getLayers().forEach(layer => {
        console.log('Layer:', layer.get('purpose'), 'zIndex:', layer.getZIndex());
      });
    }

    return () => {
      if (highlightLayerRef.current) {
        const source = highlightLayerRef.current.getSource();
        if (source) {
          source.clear();
        }
      }
      selectedFeatureRef.current = null;
      hoveredFeatureRef.current = null;
    };
  }, [selectedCategory, onMapReady, onHover]);

  // Initialize base layer
  useEffect(() => {
    if (!mapInstanceRef.current) return;
    
    const url = getAssetUrl('base', getCategoryFile(CATEGORIES, selectedCategory));
    const newCogLayer = new WebGLTileLayer({
      source: new GeoTIFF({
        sources: [{
          url: url,
        }],
        transition: 0,
        interpolate: false,
        wrapX: false,
      }),
      style: {
        color: createColorExpression(selectedBands)
      },
      visible: selectedBands.length > 0,
      zIndex: 30,
      properties: {
        purpose: 'cog'
      }
    });

    newCogLayer.on('error', (error) => {
    });

    try {
      mapInstanceRef.current.getLayers().setAt(2, newCogLayer);
    } catch (error) {
    }
  }, [selectedCategory, selectedBands]);

  // Update map interactions when brush is selected/deselected
  useEffect(() => {
    if (mapInstanceRef.current) {
      const interactions = mapInstanceRef.current.getInteractions().getArray();
      const dragPan = interactions.find(i => i instanceof DragPan);
      
      if (dragPan) {
        if (currentBrush) {
          // Disable panning when brush is active
          dragPan.setActive(false);
          
          if (mapRef.current) {
            mapRef.current.style.cursor = 'crosshair';
          }
        } else {
          // Enable panning when no brush is selected
          dragPan.setActive(true);
          
          if (mapRef.current) {
            mapRef.current.style.cursor = 'grab';
          }
        }
      }
    }
  }, [currentBrush]);

  // Add pointer move handler
  useEffect(() => {
    if (!mapInstanceRef.current || !onHover) return;

    const handlePointerMove = (event: MapBrowserEvent<UIEvent>) => {
      const coordinate = mapInstanceRef.current!.getCoordinateFromPixel(
        mapInstanceRef.current!.getEventPixel(event.originalEvent)
      );
      onHover(coordinate);
    };

    mapInstanceRef.current.on('pointermove', handlePointerMove);

    return () => {
      if (mapInstanceRef.current) {
        mapInstanceRef.current.un('pointermove', handlePointerMove);
      }
    };
  }, [onHover]);

  // Helper function to compare geometries
  const geometriesEqual = (geom1?: Geometry | null, geom2?: Geometry | null) => {
    if (!geom1 || !geom2) return false;
    const extent1 = geom1.getExtent();
    const extent2 = geom2.getExtent();
    return extent1.every((value, index) => value === extent2[index]);
  };

  // Add this effect to listen for draw start events
  useEffect(() => {
    if (!mapInstanceRef.current) return;

    const handleDrawStart = () => {
      isDrawingRef.current = true;
      justFinishedDrawingRef.current = true;
    };

    // Find and listen to all draw interactions
    mapInstanceRef.current.getInteractions().getArray()
      .filter(interaction => interaction instanceof Draw)
      .forEach(drawInteraction => {
        (drawInteraction as Draw).on('drawstart', handleDrawStart);
      });

    return () => {
      if (mapInstanceRef.current) {
        mapInstanceRef.current.getInteractions().getArray()
          .filter(interaction => interaction instanceof Draw)
          .forEach(drawInteraction => {
            (drawInteraction as Draw).un('drawstart', handleDrawStart);
          });
      }
    };
  }, []);

  // Add this effect to reset the flag when selection mode changes
  useEffect(() => {
    if (!isSelectionMode) {
      setTimeout(() => {
        isDrawingRef.current = false;
        justFinishedDrawingRef.current = false;
      }, 500);
    }
  }, [isSelectionMode]);

  const exportGeoreferencedInterventions = useCallback(() => {
    if (!mapInstanceRef.current) return null;

    const modificationLayer = mapInstanceRef.current.getLayers().getArray()
      .find(layer => layer instanceof VectorLayer && layer.get('purpose') === 'modifications') as VectorLayer<VectorSource<Feature<Geometry>>>;

    if (!modificationLayer) return null;

    const source = modificationLayer.getSource();
    const features = source?.getFeatures() || [];
    const mapProjection = 'EPSG:3857';  // Web Mercator projection
    
    // Create GeoJSON-compatible feature collection
    const featureCollection = {
      type: 'FeatureCollection' as const,
      crs: {
        type: 'name' as const,
        properties: {
          name: 'EPSG:3857'  // Use Web Mercator
        }
      },
      features: features.map((feature: Feature<Geometry>) => {
        const geometry = feature.getGeometry();
        
        // Get the grid-snapped coordinates
        let coordinates: number[][] | number[][][] | number[][][][] = [];
        let geometryType: string = 'Unknown';

        if (geometry instanceof Circle) {
          const center = geometry.getCenter();
          // Snap center to nearest meter grid
          const snappedCenter = [
            Math.round(center[0]),  // Round to nearest meter in Web Mercator
            Math.round(center[1])   // Round to nearest meter in Web Mercator
          ];
          const radius = Math.round(geometry.getRadius()); // Round radius to nearest meter
          const polygon = createCirclePolygon(snappedCenter, radius);
          coordinates = polygon.getCoordinates();
          geometryType = 'Polygon';
        } else if (geometry instanceof Polygon) {
          // Snap all polygon coordinates to the meter grid
          coordinates = geometry.getCoordinates().map(ring =>
            ring.map(coord => [
              Math.round(coord[0]),
              Math.round(coord[1])
            ])
          );
          geometryType = 'Polygon';
        } else if (geometry instanceof Point) {
          coordinates = [geometry.getCoordinates()];
          geometryType = 'Point';
        } else if (geometry instanceof LineString) {
          coordinates = geometry.getCoordinates();
          geometryType = 'LineString';
        } else if (geometry instanceof MultiPoint) {
          coordinates = geometry.getCoordinates();
          geometryType = 'MultiPoint';
        } else if (geometry instanceof MultiLineString) {
          coordinates = geometry.getCoordinates();
          geometryType = 'MultiLineString';
        } else if (geometry instanceof MultiPolygon) {
          coordinates = geometry.getCoordinates();
          geometryType = 'MultiPolygon';
        }

        return {
          type: 'Feature' as const,
          geometry: {
            type: geometryType,
            coordinates
          },
          properties: {
            ...feature.getProperties(),
            intervention: feature.get('intervention'),
            timestamp: feature.get('timestamp'),
            projection: mapProjection,
            gridResolution: 1  // 1 meter resolution
          }
        };
      })
    };

    return featureCollection;
  }, [mapInstanceRef]);

  // Helper function to create a grid-aligned circle polygon
  const createCirclePolygon = (center: number[], radius: number): Polygon => {
    const points = 64;
    const coordinates = [[center]];
    
    for (let i = 0; i < points; i++) {
      const angle = (2 * Math.PI * i) / points;
      // Round to nearest meter
      const x = Math.round(center[0] + radius * Math.cos(angle));
      const y = Math.round(center[1] + radius * Math.sin(angle));
      coordinates[0].push([x, y]);
    }
    
    coordinates[0].push(coordinates[0][0]); // Close the polygon
    return new Polygon(coordinates);
  };

  return (
    <div ref={mapRef} className="map-container">
      {mapInstanceRef.current && (
        <>
          {currentBrush && (
            <MaskLayer
              map={mapInstanceRef.current}
              selectedCategory={selectedCategory}
              currentBrush={currentBrush}
              zIndex={50}
            />
          )}
          <WebGLDrawingLayer
            map={mapInstanceRef.current}
            currentBrush={currentBrush}
            brushSize={brushSize}
            selectedArea={selectedArea}
            drawOnCanvas={drawOnCanvas}
          />
        </>
      )}
      {children}
    </div>
  );
}

function createVectorStyle(): StyleFunction {
  return function(feature: FeatureLike) {
    const featureCode = feature.get('feature_code');
    const layer = feature.get('layer');

    // Road styles
    if (layer === 'road') {
      switch (featureCode) {
        case 15710: // Motorwayf
        case 15719: // Motorway, Collapsed Dual Carriageway
          return new Style({
            stroke: new Stroke({
              color: '#06B1CA',
              width: 5
            })
          });
        
        case 15723: // Primary Road
        case 15735: // Primary Road, Collapsed Dual Carriageway
        case 15729: // A Road
        case 15739: // A Road, Collapsed Dual Carriageway
          return new Style({
            stroke: new Stroke({
              color: '#FF0000',
              width: 4
            })
          });

        case 15743: // B Road
        case 15749: // B Road, Collapsed Dual Carriageway
          return new Style({
            stroke: new Stroke({
              color: '#F19953',
              width: 3
            })
          });

        case 15750: // Minor Road
        case 15759: // Minor Road, Collapsed Dual Carriageway
          return new Style({
            stroke: new Stroke({
              color: '#999999',
              width: 2
            })
          });

        case 15760: // Local Road
        case 15761: // Local Access Road
        case 15762: // Restricted Local Access Road
          return new Style({
            stroke: new Stroke({
              color: '#CCCCCC',
              width: 1.5
            })
          });
      }
    }

    // Building styles (feature code 15014)
    if (featureCode === 15014) {
      return new Style({
        fill: new Fill({
          color: '#E4E3E2'
        }),
        stroke: new Stroke({
          color: '#BCBCBC',
          width: 0.5
        })
      });
    }

    // Water features
    if (featureCode === 15609) { // Surface water
      return new Style({
        fill: new Fill({
          color: '#C6DDF0'
        })
      });
    }

    // Woodland (feature code 15999)
    if (featureCode === 15999) {
      return new Style({
        fill: new Fill({
          color: '#C9DDCC'
        })
      });
    }

    // Default style for other features
    return new Style({
      fill: new Fill({
        color: 'rgba(204, 204, 204, 0.4)'
      }),
      stroke: new Stroke({
        color: '#666666',
        width: 1
      })
    });
  };
}