import { useCallback } from 'react';
import { Map, MapBrowserEvent } from 'ol';
import { Brush, TypedArray } from '../types';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Feature } from 'ol';
import { Geometry, Polygon, Circle } from 'ol/geom';
import { getPointResolution } from 'ol/proj';
import { Style, Fill, Stroke } from 'ol/style';
import WebGLTileLayer from 'ol/layer/WebGLTile';

interface DrawingSystemProps {
  mapInstance: Map | null;
  isDrawing: boolean;
  setIsDrawing: (drawing: boolean) => void;
  currentBrush: Brush | null;
  brushSize: number;
  selectedArea: Polygon | null;
  activeSelection: boolean;
  selectedCategory: string;
  canvasLayers: { [key: string]: HTMLCanvasElement };
  setAreaPixelSums: (sums: number[]) => void;
  setMaskVectorLayer: (layer: VectorLayer<VectorSource<Feature<Geometry>>> | null) => void;
}

export function useDrawingSystem({
  mapInstance,
  isDrawing,
  setIsDrawing,
  currentBrush,
  brushSize,
  selectedArea,
  activeSelection,
  selectedCategory,
  canvasLayers,
  setAreaPixelSums,
  setMaskVectorLayer
}: DrawingSystemProps) {
  
  // Convert brush size from m² to radius in map units (meters)
  const getBrushRadius = useCallback((brushSizeM2: number, coordinate: number[]) => {
    // Area = πr², so r = √(area/π)
    const radiusInMeters = Math.sqrt(brushSizeM2 / Math.PI);
    
    if (!mapInstance) return radiusInMeters;

    // Convert meters to map units considering the projection scale factor
    const projection = mapInstance.getView().getProjection();
    const resolution = mapInstance.getView().getResolution() || 1;
    
    // Get the point resolution at the click location
    const pointResolution = getPointResolution(
      projection,
      resolution,
      coordinate
    );

    // Scale the radius based on the point resolution
    return radiusInMeters / pointResolution;
  }, [mapInstance]);

  const draw = useCallback((event: MapBrowserEvent<UIEvent>) => {
    if (!mapInstance || !currentBrush || !activeSelection) return;

    // Get the click coordinate in map units (EPSG:3857)
    const coordinate = event.coordinate;
    
    // Get the current view resolution (meters per pixel)
    const resolution = mapInstance.getView().getResolution() || 1;
    
    // Calculate grid size in map units (1 meter in EPSG:3857)
    const gridSize = 1;
    
    // Snap to nearest grid point
    const snappedCoordinate = [
      Math.round(coordinate[0] / gridSize) * gridSize,
      Math.round(coordinate[1] / gridSize) * gridSize
    ];
    
    // Create circle geometry with fixed 1m radius
    const circleGeometry = new Circle(snappedCoordinate, gridSize);
    
    const circleFeature = new Feature({
      geometry: circleGeometry,
      intervention: currentBrush.name,
      timestamp: Date.now(),
      projection: 'EPSG:3857',
      gridSize: gridSize,
      brushSize: brushSize,
      fixed: true // Mark this feature as fixed to the grid
    });

    const modificationLayer = mapInstance.getLayers().getArray()
      .find(layer => layer instanceof VectorLayer && layer.get('purpose') === 'modifications') as VectorLayer<VectorSource<Feature<Geometry>>>;
      
    if (modificationLayer) {
      const source = modificationLayer.getSource();
      if (source) {
        source.addFeature(circleFeature);
      }
    }
  }, [mapInstance, currentBrush, brushSize, activeSelection]);

  const startDrawing = useCallback(() => {
    if (!mapInstance || !currentBrush) return;
    setIsDrawing(true);
  }, [mapInstance, currentBrush, setIsDrawing]);

  const stopDrawing = useCallback(() => {
    if (!mapInstance) return;
    setIsDrawing(false);
  }, [mapInstance, setIsDrawing]);

  return { draw, startDrawing, stopDrawing };
}