import { useCallback, useState } from 'react';
import { Map } from 'ol';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Feature } from 'ol';
import { Style, Stroke, Fill, Circle } from 'ol/style';
import Draw from 'ol/interaction/Draw';
import { loadGeoTIFF, getAssetUrl, getCategoryFile } from '../utilities';
import { CATEGORIES } from '../constants';
import { Geometry, Polygon, LineString } from 'ol/geom';
import { UserModifications, Brush } from '../types';

interface AreaSelectionSystemProps {
  mapInstance: Map | null;
  selectedCategory: string;
  bandDescriptions: string[];
  userModifications: UserModifications;
  setAreaPixelSums: (sums: number[]) => void;
  setTotalPixels: (total: number) => void;
  setActiveSelection: (active: boolean) => void;
  setSelectedArea: (area: Polygon | null) => void;
  setMaskVectorLayer: (layer: VectorLayer<VectorSource<Feature<Geometry>>> | null) => void;
  setCurrentBrush: (brush: Brush | null) => void;
  clearCanvas?: () => void;
  calculateBandSums?: () => void;
  setBrushAreas?: (areas: { [key: string]: number }) => void;
  setUserModifications: (value: React.SetStateAction<UserModifications>) => void;
  setBandSums: (value: number[]) => void;
  onDrawStart?: () => void;
  onDrawEnd?: () => void;
}

export function useAreaSelectionSystem({
  mapInstance,
  selectedCategory,
  bandDescriptions,
  userModifications,
  setAreaPixelSums,
  setTotalPixels,
  setActiveSelection,
  setSelectedArea,
  setMaskVectorLayer,
  setCurrentBrush,
  clearCanvas,
  calculateBandSums,
  setBrushAreas,
  setUserModifications,
  setBandSums,
  onDrawStart,
  onDrawEnd
}: AreaSelectionSystemProps) {
  const [isSelectionMode, setIsSelectionMode] = useState(false);
  const [selectionLayer, setSelectionLayer] = useState<VectorLayer<VectorSource<Feature<Geometry>>> | null>(null);
  const [drawInteraction, setDrawInteraction] = useState<Draw | null>(null);

  const calculateAreaStats = useCallback(async (polygon: Polygon) => {
    if (!mapInstance) {
      console.error('Map reference not found');
      return;
    }
    
    try {
      const currentFile = getCategoryFile(CATEGORIES, selectedCategory);
      const url = getAssetUrl('base', currentFile);
      
      const tiff = await loadGeoTIFF(url);
      
      const image = await tiff.getImage();

      const width = image.getWidth();
      const height = image.getHeight();
      const numBands = bandDescriptions.length;
      

      const rasterData = await image.readRasters({
        window: [0, 0, width, height],
        interleave: false,
        pool: false
      });

      if (!Array.isArray(rasterData)) {
        console.error('Unexpected raster data format');
        return;
      }

      const bandTotals = new Array(numBands).fill(0);
      let totalPixelsInPolygon = 0;

      const bbox = [
        image.getOrigin()[0],
        image.getOrigin()[1],
        image.getOrigin()[0] + width * image.getResolution()[0],
        image.getOrigin()[1] + height * image.getResolution()[1]
      ];

      const xResolution = image.getResolution()[0];
      const yResolution = image.getResolution()[1];

      let lastProgress = 0;

      for (let y = 0; y < height; y++) {
        const progress = Math.floor((y / height) * 100);
        if (progress % 10 === 0 && progress !== lastProgress) {
          lastProgress = progress;
        }

        for (let x = 0; x < width; x++) {
          const mapX = bbox[0] + (x + 0.5) * xResolution;
          const mapY = bbox[1] + (y + 0.5) * yResolution;

          if (polygon.intersectsCoordinate([mapX, mapY])) {
            totalPixelsInPolygon++;
            
            for (let band = 0; band < numBands; band++) {
              if (rasterData[band]) {
                const pixelIndex = y * width + x;
                const value = rasterData[band][pixelIndex];
                if (typeof value === 'number' && !isNaN(value)) {
                  bandTotals[band] += value;
                }
              }
            }
          }
        }
      }

      // Include modifications
      const modifications = userModifications[selectedCategory] || {};
      Object.values(modifications).forEach((modification: any) => {
        if (modification.values) {
          modification.values.forEach((value: number, index: number) => {
            bandTotals[index] += value;
          });
        }
      });

      setAreaPixelSums(bandTotals);
      setTotalPixels(totalPixelsInPolygon);

    } catch (error) {
      console.error('Error in calculateAreaStats:', error);
      if (error instanceof Error) {
        alert(`Error calculating area statistics: ${error.message}`);
      } else {
        alert('Error calculating area statistics. Please try again.');
      }
    }
  }, [mapInstance, selectedCategory, bandDescriptions, userModifications, setAreaPixelSums, setTotalPixels]);

  const toggleSelectionMode = useCallback(() => {
    if (!mapInstance) return;

    if (!isSelectionMode) {
      // Set cursor to crosshair when entering selection mode
      const mapElement = mapInstance.getTargetElement();
      if (mapElement) {
        mapElement.style.cursor = 'crosshair';
      }
      
      const source = new VectorSource();
      const layer = new VectorLayer({
        source: source,
        style: new Style({
          fill: new Fill({
            color: 'rgba(0, 0, 0, 0)'
          })
        })
      });

      const draw = new Draw({
        source: source,
        type: 'Polygon',
        style: [
          // Style for all segments including preview (but not closing line)
          new Style({
            stroke: new Stroke({
              color: 'rgba(255, 0, 0, 1)',  // Red line
              width: 2
            }),
            // This geometry function shows confirmed segments and preview, but not closing line
            geometry: (feature) => {
              const geom = feature.getGeometry();
              if (geom && geom.getType() === 'Polygon') {
                const polygonGeom = geom as Polygon;
                const coordinates = polygonGeom.getCoordinates()[0];
                if (coordinates.length > 2) {
                  // Create a LineString from all points except the closing segment
                  return new LineString(coordinates.slice(0, -1));
                }
              }
              return geom;
            }
          }),
          // Style for the vertices
          new Style({
            image: new Circle({
              radius: 5,
              fill: new Fill({
                color: 'rgba(255, 0, 0, 1)'  // Red dots for vertices
              })
            })
          })
        ]
      });

      // Call onDrawStart when starting to draw
      draw.on('drawstart', () => {
        if (onDrawStart) onDrawStart();
      });

      draw.on('drawend', (event) => {
        // Reset cursor after drawing is complete
        const mapElement = mapInstance.getTargetElement();
        if (mapElement) {
          mapElement.style.cursor = 'grab';
        }

        const polygon = event.feature.getGeometry() as Polygon;
        
        // Create and add the mask layer BEFORE removing the draw interaction
        const largeExtent = [
          [-1e7, -1e7],
          [1e7, -1e7],
          [1e7, 1e7],
          [-1e7, 1e7],
          [-1e7, -1e7]
        ];

        const polygonWithHole = new Polygon([largeExtent, polygon.getCoordinates()[0]]);
        const newMaskVectorLayer = new VectorLayer<VectorSource<Feature<Geometry>>>({
          source: new VectorSource<Feature<Geometry>>({
            features: [new Feature({
              geometry: polygonWithHole
            })]
          }),
          style: new Style({
            fill: new Fill({
              color: 'rgba(255, 253, 245, 0.5)'
            }),
            stroke: new Stroke({
              color: 'rgba(255, 0, 0, 1)',
              width: 2
            })
          }),
          zIndex: 40,
          properties: {
            purpose: 'selection-mask'
          }
        });

        // Set the selected area and add the mask layer
        setSelectedArea(polygon);
        mapInstance.addLayer(newMaskVectorLayer);
        setMaskVectorLayer(newMaskVectorLayer);
        setActiveSelection(true);
        
        // Calculate stats and update state
        calculateAreaStats(polygon);
        if (calculateBandSums) calculateBandSums();

        // Use setTimeout to remove the interaction after everything else is done
        setTimeout(() => {
          mapInstance.removeInteraction(draw);
          setIsSelectionMode(false);
          if (onDrawEnd) onDrawEnd();
        }, 100);
      });

      mapInstance.addLayer(layer);
      mapInstance.addInteraction(draw);
      
      setSelectionLayer(layer);
      setDrawInteraction(draw);
    } else {
      // Reset cursor when exiting selection mode
      const mapElement = mapInstance.getTargetElement();
      if (mapElement) {
        mapElement.style.cursor = 'grab';
      }
    }

    setIsSelectionMode(!isSelectionMode);
  }, [mapInstance, isSelectionMode, calculateAreaStats, setSelectedArea, setActiveSelection, calculateBandSums, onDrawStart, onDrawEnd]);

  const clearSelection = useCallback(() => {
    if (!mapInstance) return;
    
    // Clear the canvas using the passed function
    if (clearCanvas) {
      clearCanvas();
    }
    
    // Clear brush selection and reset cursor
    setCurrentBrush(null);
    const mapElement = mapInstance.getTargetElement();
    if (mapElement) {
      mapElement.style.cursor = 'grab';
    }

    // Remove all selection-related layers
    mapInstance.getLayers().getArray().forEach(layer => {
      // Check if it's a selection-related layer
      if (layer.get('purpose') === 'selection-mask') {
        console.log('Removing selection mask layer');
        mapInstance.removeLayer(layer);
      }
    });
    
    // Clear selection layer if it exists
    if (selectionLayer) {
      console.log('Removing selection layer');
      mapInstance.removeLayer(selectionLayer);
      setSelectionLayer(null);
    }
    
    // Remove draw interaction if it exists
    if (drawInteraction) {
      mapInstance.removeInteraction(drawInteraction);
      drawInteraction.dispose();
      setDrawInteraction(null);
    }
    
    // Reset all states
    setActiveSelection(false);
    setSelectedArea(null);
    setAreaPixelSums(new Array(bandDescriptions.length).fill(0));
    setTotalPixels(0);
    setIsSelectionMode(false);
    setMaskVectorLayer(null);

    // Clear brush areas and totals
    if (setBrushAreas) {
      setBrushAreas({});
    }

    // Reset user modifications for the current category
    setUserModifications(prev => ({
      ...prev,
      [selectedCategory]: {}
    }));

    // Reset band sums
    setBandSums(new Array(bandDescriptions.length).fill(0));

    // Force map render to ensure all visual changes are applied
    mapInstance.render();
  }, [
    mapInstance,
    selectionLayer,
    drawInteraction,
    setActiveSelection,
    setSelectedArea,
    setAreaPixelSums,
    bandDescriptions.length,
    setTotalPixels,
    setMaskVectorLayer,
    setCurrentBrush,
    setBrushAreas,
    clearCanvas,
    selectedCategory,
    setUserModifications,
    setBandSums
  ]);

  const createMaskOverlay = useCallback((selectedPolygon: Polygon) => {
    if (!mapInstance) return;

    // Use a very large extent to cover the entire map
    const largeExtent = [
      [-1e7, -1e7],
      [1e7, -1e7],
      [1e7, 1e7],
      [-1e7, 1e7],
      [-1e7, -1e7]
    ];

    // Create a feature that covers the whole map with a hole for the selected area
    const maskFeature = new Feature({
      geometry: new Polygon([
        largeExtent,
        selectedPolygon.getCoordinates()[0].slice().reverse()
      ])
    });

    const maskVectorLayer = new VectorLayer<VectorSource<Feature<Geometry>>>({
      source: new VectorSource<Feature<Geometry>>({
        features: [maskFeature]
      }),
      style: new Style({
        fill: new Fill({
          color: 'rgba(255, 253, 245, 1)'
        })
      }),
      zIndex: 3
    });

    mapInstance.addLayer(maskVectorLayer);
    setMaskVectorLayer(maskVectorLayer);
  }, [mapInstance, setMaskVectorLayer]);

  const handleSelectionComplete = useCallback((polygon: Polygon) => {
    if (!mapInstance) return;
    
    setSelectedArea(polygon);
    setActiveSelection(true);
    createMaskOverlay(polygon);
    calculateAreaStats(polygon);
    if (calculateBandSums) calculateBandSums();
  }, [mapInstance, setSelectedArea, setActiveSelection, createMaskOverlay, calculateAreaStats, calculateBandSums]);

  return {
    isSelectionMode,
    toggleSelectionMode,
    clearSelection,
    calculateAreaStats,
    createMaskOverlay,
    handleSelectionComplete
  };
}