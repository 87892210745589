import { useEffect, useState, useCallback, useRef, Dispatch, SetStateAction } from 'react';
import { Map } from 'ol';
import { Brush } from '../types';

interface CanvasSystemProps {
  mapInstance: Map | null;
  selectedBands: number[];
  bandDescriptions: string[];
  currentBrush: Brush | null;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface CanvasSystemReturn {
  canvasLayers: { [key: string]: HTMLCanvasElement };
  setCanvasLayers: Dispatch<SetStateAction<{ [key: string]: HTMLCanvasElement }>>;
  drawOnCanvas: (coordinate: number[], brushSize: number) => void;
  clearCanvas: () => void;
  bandSums: number[];
  brushAreas: { [key: string]: number };
  calculateBandSums: () => void;
  getInterventionAtPixel: (coordinate: number[]) => { brush: Brush, value: number } | null;
}

interface ModifiedCell {
  coordinate: number[];
  brush: Brush;
  timestamp: number;
  values: number[];
}

export const useCanvasSystem = ({ 
  mapInstance, 
  selectedBands, 
  bandDescriptions,
  currentBrush 
}: CanvasSystemProps) => {
  const [canvasLayers, setCanvasLayers] = useState<{[key: string]: HTMLCanvasElement}>({});
  const [bandSums, setBandSums] = useState<number[]>(new Array(bandDescriptions.length).fill(0));
  const [brushAreas, setBrushAreas] = useState<{[key: string]: number}>({});
  
  const modifiedCellsRef = useRef<{
    [key: string]: ModifiedCell
  }>({});

  // Calculate sums for all bands
  const calculateBandSums = useCallback(() => {
    const sums = new Array(bandDescriptions.length).fill(0);
    
    Object.values(modifiedCellsRef.current).forEach(cell => {
      if (cell.brush?.values) {
        cell.brush.values.forEach((value, index) => {
          sums[index] += value;
        });
      }
    });
    
    setBandSums(sums);
  }, [bandDescriptions.length]);

  // Calculate areas for each brush
  const calculateBrushAreas = useCallback(() => {
    const areas: {[key: string]: number} = {};
    
    Object.values(modifiedCellsRef.current).forEach(cell => {
      const brushName = cell.brush?.name;
      if (brushName) {
        areas[brushName] = (areas[brushName] || 0) + 1; // Each cell is 1m²
      }
    });
    
    setBrushAreas(areas);
  }, []);

  // Draw function for all canvases
  const redrawCanvases = useCallback(() => {
    if (!mapInstance || !canvasLayers) return;
    
    const size = mapInstance.getSize();
    const view = mapInstance.getView();
    
    if (!size || !view || !view.getProjection()) return;

    Object.entries(canvasLayers).forEach(([layerKey, canvas]) => {
      const ctx = canvas.getContext('2d');
      if (!ctx) return;

      // Clear the canvas
      ctx.clearRect(0, 0, size[0], size[1]);

      // Draw all modified cells
      Object.entries(modifiedCellsRef.current).forEach(([_, cell]) => {
        // Skip invalid coordinates
        if (!cell.coordinate || cell.coordinate.length !== 2) return;

        try {
          const pixel = mapInstance.getPixelFromCoordinate(cell.coordinate);
          if (!pixel || pixel.some(isNaN)) return;  // Skip if pixel conversion failed

          const resolution = view.getResolution() || 1;
          
          // Set the fill style based on the layer type
          if (layerKey === 'base') {
            // In base view, use RGB values
            ctx.fillStyle = cell.brush?.color || 'rgba(0, 0, 0, 0.3)';
          } else {
            // In band view, only show the value for the current band
            const bandIndex = parseInt(layerKey.replace('band_', ''));
            if (cell.brush?.values && bandIndex < cell.brush.values.length) {
              const value = cell.brush.values[bandIndex];
              // Only show the value if it's non-zero
              if (value > 0) {
                // Use green for positive values (same as base bands)
                ctx.fillStyle = `rgba(0, 255, 0, ${value / 255})`;
              } else {
                // Skip drawing if value is 0
                return;
              }
            }
          }

          // Draw the cell
          const cellSize = 1 / resolution;
          ctx.fillRect(
            pixel[0] - cellSize / 2,
            pixel[1] - cellSize / 2,
            cellSize,
            cellSize
          );
        } catch (error) {
          console.warn('Failed to draw cell:', error);
        }
      });
    });
  }, [mapInstance, canvasLayers]);

  const drawOnCanvas = useCallback((coordinate: number[], brushSize: number) => {
    if (!mapInstance || !coordinate || coordinate.length !== 2) return;

    const view = mapInstance.getView();
    if (!view || !view.getProjection()) return;

    try {
      // Calculate brush radius in meters (half of brush size)
      const radiusInMeters = Math.floor(brushSize / 2);
      
      // Get center cell coordinates
      const centerX = Math.round(coordinate[0]);
      const centerY = Math.round(coordinate[1]);
      
      // Draw all cells within the brush radius
      for (let dx = -radiusInMeters; dx <= radiusInMeters; dx++) {
        for (let dy = -radiusInMeters; dy <= radiusInMeters; dy++) {
          // Calculate current cell coordinates
          const cellX = centerX + dx;
          const cellY = centerY + dy;
          
          // Check if this cell is within the circular brush area
          const distanceSquared = dx * dx + dy * dy;
          if (distanceSquared > radiusInMeters * radiusInMeters) continue;
          
          const cellKey = `${cellX},${cellY}`;

          if (currentBrush?.name === 'eraser') {
            // Remove the cell if it exists
            if (modifiedCellsRef.current[cellKey]) {
              delete modifiedCellsRef.current[cellKey];
            }
          } else if (currentBrush) {
            // For non-eraser brushes, add or update the cell
            modifiedCellsRef.current[cellKey] = {
              coordinate: [cellX, cellY],
              brush: currentBrush,
              timestamp: Date.now(),
              values: currentBrush.values || new Array(bandDescriptions.length).fill(0)
            };
          }
        }
      }

      // Update visuals and calculations
      redrawCanvases();
      calculateBandSums();
      calculateBrushAreas();
    } catch (error) {
      console.warn('Failed to draw:', error);
    }
  }, [
    mapInstance,
    currentBrush,
    bandDescriptions.length,
    redrawCanvases,
    calculateBandSums,
    calculateBrushAreas
  ]);

  // Initialize canvases
  useEffect(() => {
    if (!mapInstance) return;

    const size = mapInstance.getSize();
    if (!size) return;

    const mapViewport = mapInstance.getViewport();
    const newCanvasLayers: {[key: string]: HTMLCanvasElement} = {};

    // Create base canvas
    const baseCanvas = document.createElement('canvas');
    baseCanvas.className = 'map-canvas-layer';
    baseCanvas.width = size[0];
    baseCanvas.height = size[1];
    baseCanvas.style.position = 'absolute';
    baseCanvas.style.top = '0';
    baseCanvas.style.left = '0';
    baseCanvas.style.pointerEvents = 'none';
    baseCanvas.style.zIndex = '10';
    baseCanvas.setAttribute('crossOrigin', 'anonymous');
    newCanvasLayers['base'] = baseCanvas;

    // Create canvas for each band
    bandDescriptions.forEach((_, index) => {
      const bandCanvas = document.createElement('canvas');
      bandCanvas.className = 'map-canvas-layer';
      bandCanvas.width = size[0];
      bandCanvas.height = size[1];
      bandCanvas.style.position = 'absolute';
      bandCanvas.style.top = '0';
      bandCanvas.style.left = '0';
      bandCanvas.style.pointerEvents = 'none';
      bandCanvas.style.zIndex = '10';
      bandCanvas.style.display = 'none';
      bandCanvas.setAttribute('crossOrigin', 'anonymous');
      newCanvasLayers[`band_${index}`] = bandCanvas;
    });

    // Add canvases to map viewport
    Object.values(newCanvasLayers).forEach(canvas => {
      mapViewport.appendChild(canvas);
    });

    setCanvasLayers(newCanvasLayers);

    return () => {
      Object.values(newCanvasLayers).forEach(canvas => {
        canvas.remove();
      });
    };
  }, [mapInstance, bandDescriptions]);

  // Set up map render handler in a separate effect
  useEffect(() => {
    if (!mapInstance) return;

    const handleMapRender = () => {
      requestAnimationFrame(redrawCanvases);
    };

    mapInstance.on('postrender', handleMapRender);

    return () => {
      mapInstance.un('postrender', handleMapRender);
    };
  }, [mapInstance, redrawCanvases]);

  // Handle canvas visibility
  useEffect(() => {
    if (!canvasLayers || Object.keys(canvasLayers).length === 0) return;
    
    try {
      // Hide ALL canvases first
      Object.entries(canvasLayers).forEach(([_, canvas]) => {
        if (canvas && canvas.style) {
          canvas.style.display = 'none';
        }
      });
      
      // Then show ONLY the selected canvas
      if (selectedBands.length === 0) {
        // Show base canvas when no band is selected
        const baseCanvas = canvasLayers['base'];
        if (baseCanvas && baseCanvas.style) {
          baseCanvas.style.display = 'block';
        }
      } else {
        // Show only the selected band's canvas
        selectedBands.forEach(bandIndex => {
          const selectedCanvas = canvasLayers[`band_${bandIndex - 1}`];
          if (selectedCanvas && selectedCanvas.style) {
            selectedCanvas.style.display = 'block';
          }
        });
      }
    } catch (error) {
      console.error('Error updating canvas visibility:', error);
    }
  }, [selectedBands, canvasLayers]);

  // Modify clearCanvas to reset all drawing-related data
  const clearCanvas = useCallback(() => {
    modifiedCellsRef.current = {};
    setBandSums(new Array(bandDescriptions.length).fill(0));
    setBrushAreas({}); // Reset brush areas
    redrawCanvases();
  }, [redrawCanvases, bandDescriptions.length]);

  // Add this new function inside useCanvasSystem
  const getInterventionAtPixel = useCallback((coordinate: number[]) => {
    if (!mapInstance) return null;
    
    const pixel = mapInstance.getPixelFromCoordinate(coordinate);
    if (!pixel) return null;

    const resolution = mapInstance.getView().getResolution() || 1;
    const cellSize = 1 / resolution;
    const cellX = Math.floor(pixel[0] / cellSize) * cellSize;
    const cellY = Math.floor(pixel[1] / cellSize) * cellSize;
    
    const cellKey = `${cellX},${cellY}`;
    return modifiedCellsRef.current[cellKey] || null;
  }, [mapInstance]);

  return {
    canvasLayers,
    setCanvasLayers,
    drawOnCanvas,
    clearCanvas,
    bandSums,
    brushAreas,
    calculateBandSums,
    getInterventionAtPixel
  };
};