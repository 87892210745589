import { BrushesData } from '../types';

export const brushesData: BrushesData = {
  green_infrastructure: [
    {
      name: "Rain Garden",
      description: "Natural drainage system that filters runoff",
      cost: 100,
      color: "rgba(171, 145, 68, 0.6)",
      index: 0,
      values: [255, 96, 176, 32, 208, 64, 224, 128, 160, 192]
    },
    {
      name: "Bio-Filter",
      description: "Natural filtration system using plants and soil media",
      cost: 185,
      color: "rgba(125, 175, 95, 0.6)",
      index: 1,
      values: [96, 208, 128, 224, 32, 192, 255, 64, 176, 160]
    },
    {
      name: "Urban Forest",
      description: "Dense tree canopy providing multiple ecosystem services",
      cost: 200,
      color: "rgba(56, 142, 60, 0.6)",
      index: 2,
      values: [255, 255, 192, 255, 128, 255, 255, 96, 255, 160]
    },
    {
      name: "Green Roof",
      description: "Vegetated roof system for building sustainability",
      cost: 300,
      color: "rgba(129, 199, 132, 0.6)",
      index: 3,
      values: [176, 128, 160, 96, 192, 128, 224, 32, 64, 128]
    },
    {
      name: "Living Wall",
      description: "Vertical garden system for buildings",
      cost: 250,
      color: "rgba(165, 214, 167, 0.6)",
      index: 4,
      values: [192, 96, 128, 64, 32, 176, 192, 32, 96, 160]
    },
    {
      name: "Wetland",
      description: "Constructed wetland ecosystem for water management",
      cost: 280,
      color: "rgba(200, 230, 201, 0.6)",
      index: 5,
      values: [176, 160, 255, 255, 255, 128, 192, 255, 255, 128]
    },
    {
      name: "Bio-Retention",
      description: "Engineered system for stormwater treatment",
      cost: 170,
      color: "rgba(232, 245, 233, 0.6)",
      index: 6,
      values: [128, 96, 192, 96, 255, 128, 176, 255, 96, 128]
    },
    {
      name: "Green Space",
      description: "Multi-purpose recreational and ecological area",
      cost: 80,
      color: "rgba(27, 94, 32, 0.6)",
      index: 7,
      values: [255, 176, 160, 192, 128, 160, 192, 160, 192, 255]
    },
    {
      name: "Pollinator Path",
      description: "Corridor designed to support pollinator species",
      cost: 85,
      color: "rgba(46, 125, 50, 0.6)",
      index: 8,
      values: [192, 96, 128, 255, 96, 128, 160, 128, 255, 192]
    },
    {
      name: "Native Garden",
      description: "Garden featuring local plant species",
      cost: 90,
      color: "rgba(102, 187, 106, 0.6)",
      index: 9,
      values: [224, 128, 160, 255, 128, 160, 176, 160, 255, 192]
    },
    {
      name: "Urban Meadow",
      description: "Natural grassland ecosystem in urban setting",
      cost: 95,
      color: "rgba(156, 204, 101, 0.6)",
      index: 10,
      values: [224, 160, 176, 255, 160, 128, 192, 192, 224, 255]
    },
    {
      name: "Wildlife Garden",
      description: "Habitat-focused garden supporting local fauna",
      cost: 145,
      color: "rgba(174, 213, 129, 0.6)",
      index: 11,
      values: [192, 128, 160, 255, 128, 160, 176, 160, 255, 192]
    },
    {
      name: "Butterfly Garden",
      description: "Specialized garden for butterfly habitat",
      cost: 110,
      color: "rgba(196, 223, 155, 0.6)",
      index: 12,
      values: [176, 96, 128, 255, 96, 128, 160, 128, 224, 192]
    },
    {
      name: "Herb Garden",
      description: "Garden for culinary and medicinal plants",
      cost: 75,
      color: "rgba(220, 237, 200, 0.6)",
      index: 13,
      values: [160, 64, 96, 128, 64, 96, 128, 96, 128, 255]
    },
    {
      name: "Food Garden",
      description: "Urban agriculture space for food production",
      cost: 130,
      color: "rgba(241, 248, 233, 0.6)",
      index: 14,
      values: [192, 96, 128, 160, 96, 128, 160, 128, 160, 255]
    },
    {
      name: "Green Corridor",
      description: "Linear park connecting green spaces",
      cost: 220,
      color: "rgba(220, 237, 200, 0.6)",
      index: 15,
      values: [255, 192, 176, 255, 160, 192, 224, 160, 255, 255]
    },
    {
      name: "Eco-Corridor",
      description: "Wildlife passage through urban areas",
      cost: 180,
      color: "rgba(196, 223, 155, 0.6)",
      index: 16,
      values: [224, 160, 160, 255, 128, 160, 192, 128, 255, 192]
    },
    {
      name: "Green Channel",
      description: "Vegetated waterway for drainage",
      cost: 165,
      color: "rgba(174, 213, 129, 0.6)",
      index: 17,
      values: [176, 128, 192, 160, 255, 160, 192, 255, 192, 160]
    },
    {
      name: "Bioswale",
      description: "Landscaped drainage course with vegetation",
      cost: 120,
      color: "rgba(156, 204, 101, 0.6)",
      index: 18,
      values: [160, 96, 176, 128, 255, 128, 176, 255, 160, 128]
    },
    {
      name: "Rain Basin",
      description: "Depression for collecting and filtering rainwater",
      cost: 160,
      color: "rgba(139, 195, 74, 0.6)",
      index: 19,
      values: [128, 64, 160, 96, 255, 96, 160, 255, 128, 96]
    },
    {
      name: "Green Buffer",
      description: "Vegetated barrier for environmental protection",
      cost: 140,
      color: "rgba(124, 179, 66, 0.6)",
      index: 20,
      values: [224, 160, 176, 192, 160, 224, 208, 160, 224, 160]
    },
    {
      name: "Green Screen",
      description: "Vertical growing system for screening",
      cost: 190,
      color: "rgba(104, 159, 56, 0.6)",
      index: 21,
      values: [160, 96, 128, 96, 64, 192, 176, 64, 128, 160]
    },
    {
      name: "Permeable Surface",
      description: "Water-permeable paving system",
      cost: 150,
      color: "rgba(85, 139, 47, 0.6)",
      index: 22,
      values: [32, 32, 96, 64, 255, 64, 128, 255, 96, 255]
    },
    {
      name: "Eco-Island",
      description: "Isolated habitat patch in urban matrix",
      cost: 240,
      color: "rgba(51, 105, 30, 0.6)",
      index: 23,
      values: [192, 128, 160, 255, 128, 160, 192, 128, 224, 192]
    }
  ]
};