/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useState, useEffect, useCallback, useRef } from 'react';
import { MapCore } from './components/MapCore';
import { brushesData } from './data/brushes';
import 'ol/ol.css';
import { EventsKey } from 'ol/events';
import { Feature } from 'ol';
import { Point } from 'ol/geom';
import { Polygon } from 'ol/geom';
import { MapBrowserEvent } from 'ol';
import { Map } from 'ol';
import WebGLTileLayer from 'ol/layer/WebGLTile';
import GeoTIFF from 'ol/source/GeoTIFF';
import { calculateModificationImpact, getCategoryFile, polygonToClipCoordinates } from './utilities';
import { CATEGORIES, SELECTED_BLUE, SELECTED_BLUE_BG, BORDER_COLOR } from './constants';
import { Style, Fill, Stroke, Circle as StyleCircle } from 'ol/style';
import { getAssetUrl } from './utilities';
import { useDrawingSystem } from './components/DrawingSystem';
import { useAreaSelectionSystem } from './components/AreaSelectionSystem';
import { useSearchSystem } from './components/SearchSystem';
import { useCanvasSystem } from './components/CanvasSystem';
import { fromUrl } from 'geotiff';
import { Geometry } from 'ol/geom';
import RenderEvent from 'ol/render/Event';
import { FeatureLike } from 'ol/Feature';
import { Draw } from 'ol/interaction';
import { DrawEvent } from 'ol/interaction/Draw';
import { ReportGenerator } from './services/ReportGenerator';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { PDFReportLayout } from './components/PDFReportLayout';
import { BlobProvider } from '@react-pdf/renderer';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import { PaymentModal } from './components/PaymentModal';
import { NominatimResult } from './components/SearchSystem';
import { InfoTooltip } from './components/InfoTooltip';

// Import types
import {
  Brush,
  BrushesData,
  UserModifications,
} from './types';

import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Layer } from 'ol/layer';
import TileLayer from 'ol/layer/Tile';
import VectorTileLayer from 'ol/layer/VectorTile';

// Add this interface definition
interface ReportData {
  baseMapImage: string;
  bandImages: BandImage[];
  bandDescriptions: string[];
  interventions: Array<{
    name: string;
    area: number;
    cost: number;
    color: string;
    values: number[];
  }>;
  bandTotals: number[];
  totalCost: number;
  selectedArea: number;
  dateGenerated: Date;
  projectName: string;
  location: string;
}

// Add interface for band images
interface BandImage {
  base: string;
  withInterventions: string;
}

export function App() {
  const [currentBrush, setCurrentBrush] = useState<Brush | null>(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [brushes] = useState<BrushesData>(brushesData);

  const [selectedCategory] = useState('green_infrastructure');
  const [selectedBands, setSelectedBands] = useState<number[]>([]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [bandDescriptions, setBandDescriptions] = useState<string[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pixelValues, setPixelValues] = useState<number[]>([]);

  const [brushSize, setBrushSize] = useState(5); // 5m² initial area
  const [interventionLayer, setInterventionLayer] = useState<WebGLTileLayer | null>(null);
  // const [brushUsage, setBrushUsage] = useState<Record<string, number>>({});
  const [totalCost, setTotalCost] = useState<number>(0);
  const [bandTotals, setBandTotals] = useState<number[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [rasterInfo, setRasterInfo] = useState<any>(null);
  const [modificationLayer, setModificationLayer] = useState<VectorLayer<VectorSource> | null>(null);

  const [userModifications, setUserModifications] = useState<UserModifications>({});
  const [bandSums, setBandSums] = useState<number[]>(new Array(bandDescriptions.length).fill(0));
  // const [currentCategory, setCurrentCategory] = useState<string>('energy');

  const [selectedArea, setSelectedArea] = useState<Polygon | null>(null);
  const [areaPixelSums, setAreaPixelSums] = useState<number[]>([]);
  const [totalPixels, setTotalPixels] = useState<number>(0);
  const [activeSelection, setActiveSelection] = useState<boolean>(false);

  const mapInstanceRef = useRef<Map | null>(null);

  // Replace all canvas-related code with the hook
  const { 
    canvasLayers, 
    drawOnCanvas, 
    clearCanvas, 
    bandSums: canvasBandSums,
    brushAreas: canvasBrushAreas,
    calculateBandSums,
    getInterventionAtPixel
  } = useCanvasSystem({
    mapInstance: mapInstanceRef.current,
    selectedBands,
    bandDescriptions,
    currentBrush
  });

  const [maskLayer, setMaskLayer] = useState<WebGLTileLayer | null>(null);
  const [maskVectorLayer, setMaskVectorLayer] = useState<VectorLayer<VectorSource<Feature<Geometry>>> | null>(null);

  const [brushSizes, setBrushSizes] = useState<Record<string, number>>(() => {
    // Initialize with default size (5) for each brush
    const sizes: Record<string, number> = {};
    Object.values(brushesData).forEach(categoryBrushes => {
      categoryBrushes.forEach(brush => {
        sizes[brush.name] = 5;
      });
    });
    return sizes;
  });

  const selectBrush = useCallback((brush: Brush) => {
    // Don't allow brush selection if no area is selected
    if (!activeSelection) {
      return; // Just return without showing alert
    }

    if (currentBrush?.name === brush.name) {
      setCurrentBrush(null);
    } else {
      setCurrentBrush(brush);
      setBrushSize(brushSizes[brush.name]);
    }
  }, [currentBrush, brushSizes, activeSelection]);

  const updateModificationLayer = useCallback(() => {
    if (!modificationLayer || !mapInstanceRef.current) return;

    const source = modificationLayer.getSource();
    if (!(source instanceof VectorSource)) return;

    const features: Feature[] = [];

    Object.entries(userModifications[selectedCategory] || {}).forEach(([pixelKey, modification]) => {
      const [lon, lat] = pixelKey.split(',').map(Number);
      const feature = new Feature({
        geometry: new Point([lon, lat]),
        brushName: modification.brushName,
        brushSize: modification.brushSize,
      });
      features.push(feature);
    });


    // Clear existing features and add new ones
    source.clear();
    source.addFeatures(features);

    modificationLayer.changed();
    mapInstanceRef.current.render();
  }, [modificationLayer, mapInstanceRef, selectedCategory, userModifications]);

  // Remove the draw, startDrawing, and stopDrawing functions
  // Replace them with the hook:
  const { draw, startDrawing, stopDrawing } = useDrawingSystem({
    mapInstance: mapInstanceRef.current,
    isDrawing,
    setIsDrawing,
    currentBrush,
    brushSize,
    selectedArea,
    activeSelection,
    selectedCategory,
    canvasLayers,
    setAreaPixelSums,
    setMaskVectorLayer
  });

  // Update the band change handler
  const handleBandSelection = useCallback((bandNumber: number) => (event: React.MouseEvent) => {
    setSelectedBands(prev => {
      const newBands = prev.includes(bandNumber)
        ? prev.filter(b => b !== bandNumber)
        : [...prev, bandNumber];
      
      // Only update the layer style if we have a valid band selection
      if (mapInstanceRef.current) {
        const cogLayer = mapInstanceRef.current.getLayers().getArray()
          .find(layer => layer instanceof WebGLTileLayer && layer.get('purpose') === 'cog') as WebGLTileLayer;
        
        if (cogLayer) {
          // Ensure we have at least one band before applying style
          if (newBands.length > 0) {
            const bandExpressions = newBands.map(band => ['band', band]);
            cogLayer.setStyle({
              color: [
                'array',
                ['*', 0.3, ['clamp', bandExpressions.length === 1 ? bandExpressions[0] : ['+', ...bandExpressions], 0, 1]],
                ['*', 0.8, ['clamp', bandExpressions.length === 1 ? bandExpressions[0] : ['+', ...bandExpressions], 0, 1]],
                ['*', 0.3, ['clamp', bandExpressions.length === 1 ? bandExpressions[0] : ['+', ...bandExpressions], 0, 1]],
                0.6
              ]
            });
          } else {
            // Reset to default style when no bands are selected
            cogLayer.setStyle({
              color: ['array', 0, 0, 0, 0]
            });
          }
        }
      }
      
      return newBands;
    });
  }, [mapInstanceRef]);

  // Update the category change handler
  const updateLayer = () => {
    if (!mapInstanceRef.current) return;
    
    // Create a new layer with error handling
    const url = getAssetUrl('base', getCategoryFile(CATEGORIES, selectedCategory));
    
    const newCogLayer = new WebGLTileLayer({
      source: new GeoTIFF({
        sources: [{
          url: url,
        }],
        transition: 0,
        interpolate: false,
        wrapX: false,
      }),
      style: {
        color: selectedBands.length > 0 ? [
          'array',
          ['/', ['+', ...selectedBands.map(band => ['band', band])], selectedBands.length],
          ['/', ['+', ...selectedBands.map(band => ['band', band])], selectedBands.length],
          ['/', ['+', ...selectedBands.map(band => ['band', band])], selectedBands.length],
          0.3
        ] : ['array', 0, 0, 0, 0]
      },
      visible: selectedBands.length > 0,
      zIndex: 1
    });

    // Add error handling for the layer
    newCogLayer.on('error', (error) => {
      console.error('[COG] Layer error:', error);
      alert('Error loading map layer. Please try again later.');
    });

    try {
      mapInstanceRef.current.getLayers().setAt(1, newCogLayer);
    } catch (error) {
      console.error('[COG] Error replacing layer:', error);
      alert('Error updating map. Please refresh the page.');
    }

    setCurrentBrush(null);

    // Remove existing mask layer if any
    if (maskLayer && mapInstanceRef.current) {
      mapInstanceRef.current.removeLayer(maskLayer);
      setMaskLayer(null);
    }
  };

  // Add effect to handle initial layer setup
  useEffect(() => {
    if (!mapInstanceRef.current) return;

    const cogLayer = mapInstanceRef.current.getLayers().getArray()[1] as WebGLTileLayer;
    if (!(cogLayer instanceof WebGLTileLayer)) return;

    // Set initial visibility
    cogLayer.setVisible(selectedBands.length > 0);

  }, [selectedBands]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const drawAtPixel = (x: number, y: number) => {
    // Implementation here
  };

  useEffect(() => {
    if (mapInstanceRef.current) {
      mapInstanceRef.current.updateSize();
    }
  }, []);

  // Add effect for loading intervention layer
  useEffect(() => {
    if (!mapInstanceRef.current) return;

    const interventionFile = `${selectedCategory}_base.tif`;
    const interventionSource = new GeoTIFF({
      sources: [
        {
          url: `${window.location.origin}/bucket/base/${interventionFile}`,
        },
      ],
    });

    const newInterventionLayer = new WebGLTileLayer({
      source: interventionSource,
      style: {
        color: ['color', 255, 0, 0, 0.5], // Semi-transparent red
      },
      visible: false,
    });

    setInterventionLayer(newInterventionLayer);
    mapInstanceRef.current.addLayer(newInterventionLayer);

    return () => {
      if (mapInstanceRef.current && interventionLayer) {
        mapInstanceRef.current.removeLayer(interventionLayer);
      }
    };
  }, [selectedCategory]);

  // Add effect for calculating total cost
  useEffect(() => {
    const newTotalCost = Object.values(userModifications).reduce((total, categoryModifications) => {
      return total + Object.values(categoryModifications).reduce((categoryTotal, modification) => {
        const brush = brushes[selectedCategory]?.find((b: Brush) => b.name === modification.brushName);
        return categoryTotal + (brush ? brush.cost : 0);
      }, 0);
    }, 0);
    setTotalCost(newTotalCost);
  }, [userModifications, brushes, selectedCategory]);

  // Add effect for calculating band totals
  useEffect(() => {
    if (!mapInstanceRef.current) return;

    const cogLayer = mapInstanceRef.current.getLayers().getArray()[0] as WebGLTileLayer;
    const extent = cogLayer.getExtent();
    
    if (!extent) return;

    const calculateTotals = async () => {
      const data = await cogLayer.getData(extent);
      if (!data) return;

      // Check if data is an object with a 'data' property
      const pixelData = 'data' in data ? data.data : data;

      if (!(pixelData instanceof Float32Array || pixelData instanceof Uint8Array || pixelData instanceof Uint8ClampedArray)) {
        console.error('Unexpected data type:', pixelData);
        return;
      }

      const width = 'width' in data && typeof data.width === 'number' ? data.width : Math.sqrt(pixelData.length);
      const height = 'height' in data && typeof data.height === 'number' ? data.height : Math.sqrt(pixelData.length);
      const bandCount = Math.round(pixelData.length / (width * height));

      const totals = new Array(bandCount).fill(0);

      for (let i = 0; i < pixelData.length; i += bandCount) {
        for (let j = 0; j < bandCount; j++) {
          totals[j] += pixelData[i + j];
        }
      }

      setBandTotals(totals);
    };

    calculateTotals();
  }, [selectedCategory]);

  // Add caching function
  const fetchWithCache = async (url: string) => {
    const cache = await caches.open('geotiff-cache');
    let response = await cache.match(url);
    if (!response) {
      response = await fetch(url);
      cache.put(url, response.clone());
    }
    return response;
  };

  // Update the fetchMetadata function
  const fetchMetadata = async () => {
    const selectedFile = CATEGORIES.find(cat => cat.value === selectedCategory)?.file;
    if (!selectedFile) return;

    try {
      const url = getAssetUrl('base', selectedFile);

      const tiff = await fromUrl(url);

      const image = await tiff.getImage();

      const metadata = await image.getFileDirectory();

      if (metadata && metadata.GDAL_METADATA) {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(metadata.GDAL_METADATA, "text/xml");

        const items = Array.from(xmlDoc.getElementsByTagName('Item'));

        const bandDescriptions = items
          .filter(item => item.getAttribute('name') === 'DESCRIPTION')
          .map(item => item.textContent || '');

        setBandDescriptions(bandDescriptions);
      } else {
        // Try alternative metadata sources
        const descriptions = [];
        for (let i = 1; i <= metadata.StripOffsets.length; i++) {
          const desc = metadata[`GDAL_DESCRIPTION_${i}`] || 
                      metadata[`DESCRIPTION_${i}`] ||
                      metadata[`Band_${i}`];
          descriptions.push(desc || `Band ${i}`);
        }
        if (descriptions.length > 0) {
          setBandDescriptions(descriptions);
        }
      }
    } catch (error) {
      console.error('[ERROR] Metadata extraction failed:', error);
      setBandDescriptions(Array(10).fill('Band'));
    }
  };

  useEffect(() => {
    fetchMetadata();
  }, [selectedCategory, CATEGORIES]);

  useEffect(() => {
    if (!mapInstanceRef.current) return;

    const modificationSource = new VectorSource();
    const newModificationLayer = new VectorLayer({
      source: modificationSource,
      style: (feature) => {
        const brushName = feature.get('brushName');
        const brushSizeMapUnits = feature.get('brushSize') || 5;
        const color = currentBrush?.color || 'rgba(255, 0, 0, 0.3)';
        
        // Convert the map units to screen pixels for the style
        const resolution = mapInstanceRef.current?.getView().getResolution() || 1;
        const pixelRadius = brushSizeMapUnits / resolution;
        
        return new Style({
          image: new StyleCircle({
            radius: pixelRadius,
            fill: new Fill({ color: color }),
            stroke: new Stroke({ color: 'black', width: 1 })
          }),
        });
      },
      zIndex: 10,
    });

    mapInstanceRef.current.addLayer(newModificationLayer);
    setModificationLayer(newModificationLayer);

    return () => {
      if (mapInstanceRef.current) {
        mapInstanceRef.current.removeLayer(newModificationLayer);
      }
    };
  }, [mapInstanceRef]);

  useEffect(() => {
    if (mapInstanceRef.current && modificationLayer) {
      const layers = mapInstanceRef.current.getLayers();
      if (!layers.getArray().includes(modificationLayer)) {
        mapInstanceRef.current.addLayer(modificationLayer);
      }
      modificationLayer.setVisible(true);
      modificationLayer.setZIndex(Infinity); // Ensure it's on top
    }
  }, [modificationLayer]);

  useEffect(() => {
    const map = mapInstanceRef.current;
    if (!map) return;

    // Force map to update its size after initialization
    setTimeout(() => {
      map.updateSize();
    }, 100);
  }, []);

  useEffect(() => {
    if (mapInstanceRef.current) {
      const webGLLayers = mapInstanceRef.current.getLayers().getArray().filter(layer => layer instanceof WebGLTileLayer);
      const listeners: EventsKey[] = [];

      webGLLayers.forEach(layer => {
        const listener = layer.on('change', () => {
        });
        listeners.push(listener);
      });

      // Cleanup function to remove listeners when component unmounts
      return () => {
        listeners.forEach(listener => {
          if (listener && listener.target) {
            listener.target.removeEventListener(listener.type, listener.listener);
          }
        });
      };
    }
  }, []);

  useEffect(() => {
  }, [currentBrush]);

  useEffect(() => {
    if (!mapInstanceRef.current) return;

    const handlePointerMove = async (event: MapBrowserEvent<UIEvent>) => {
      const pixel = mapInstanceRef.current!.getEventPixel(event.originalEvent);
      const layers = mapInstanceRef.current!.getLayers().getArray();
      const cogLayer = layers.find(layer => layer instanceof WebGLTileLayer) as WebGLTileLayer;
      
      if (cogLayer && cogLayer instanceof WebGLTileLayer) {
        try {
          // Get the data at the current pixel d
          const data = await cogLayer.getData(pixel);
          if (data) {
            // If data is an array-like object, convert it to an array
            const pixelData = Array.isArray(data) ? data : Array.from(data as Float32Array);
            setPixelValues(pixelData);
          }
        } catch (error) {
          console.error('Error getting pixel data:', error);
        }
      }
    };

    // Debounce the handler to improve performance
    const debouncedHandler = (event: MapBrowserEvent<UIEvent>) => {
      setTimeout(() => handlePointerMove(event), 50);
    };

    mapInstanceRef.current.on('pointermove', debouncedHandler);

    return () => {
      if (mapInstanceRef.current) {
        mapInstanceRef.current.un('pointermove', debouncedHandler);
      }
    };
  }, [mapInstanceRef.current]);

  // Replace the area selection functions with the hook
  const {
    isSelectionMode,
    toggleSelectionMode,
    clearSelection,
    calculateAreaStats
  } = useAreaSelectionSystem({
    mapInstance: mapInstanceRef.current,
    selectedCategory,
    bandDescriptions,
    userModifications,
    setAreaPixelSums,
    setTotalPixels,
    setActiveSelection,
    setSelectedArea,
    setMaskVectorLayer,
    setCurrentBrush,
    clearCanvas,
    calculateBandSums,
    setUserModifications,
    setBandSums: (newBandSums) => {
      // Update any UI components that depend on bandSums
      calculateBandSums();
    }
  });

  // Replace the searchHandlers useMemo with the new hook
  const {
    searchQuery,
    setSearchQuery,
    search
  } = useSearchSystem({
    mapInstance: mapInstanceRef.current,
    selectedBands,
    selectedCategory,
    CATEGORIES
  });

  // Add effect to handle canvas resize
  useEffect(() => {
    if (!mapInstanceRef.current || !canvasLayers) return;

    const updateCanvasSizes = () => {
      const size = mapInstanceRef.current!.getSize();
      if (!size) return;

      Object.values(canvasLayers).forEach(canvas => {
        canvas.width = size[0];
        canvas.height = size[1];
        
        const gl = (canvas as any).gl as WebGLRenderingContext;
        if (gl) {
          gl.viewport(0, 0, canvas.width, canvas.height);
        }
      });
    };

    // Initial update
    updateCanvasSizes();

    // Handle window resize
    window.addEventListener('resize', updateCanvasSizes);

    // Handle container resize
    const observer = new ResizeObserver(updateCanvasSizes);
    observer.observe(mapInstanceRef.current.getTargetElement());

    return () => {
      window.removeEventListener('resize', updateCanvasSizes);
      observer.disconnect();
    };
  }, [canvasLayers, mapInstanceRef]);

  // Add an effect to recalculate area stats when modifications change
  useEffect(() => {
    if (!selectedArea || !activeSelection) return;

    const recalculateAreaStats = async () => {
      try {
        // Get base stats from GeoTIFF
        await calculateAreaStats(selectedArea);

        // Add impact of all modifications
        const modifications = userModifications[selectedCategory] || {};
        const modificationImpact = new Array(bandDescriptions.length).fill(0);

        Object.entries(modifications).forEach(([coordString, modification]) => {
          const coordinate = coordString.split(',').map(Number);
          const impactValues = calculateModificationImpact(
            coordinate,
            modification.values,
            selectedArea
          );
          
          impactValues.forEach((value, index) => {
            modificationImpact[index] += value;
          });
        });

        // Update area sums with modification impact
        setAreaPixelSums(prev => 
          prev.map((value, index) => value + modificationImpact[index])
        );

      } catch (error) {
        console.error('Error recalculating area stats:', error);
      }
    };

    recalculateAreaStats();
  }, [userModifications, selectedCategory, selectedArea, activeSelection]);

  // Add cleanup effect for mask layer
  useEffect(() => {
    return () => {
      if (mapInstanceRef.current) {
        if (maskLayer) {
          mapInstanceRef.current.removeLayer(maskLayer);
        }
        if (interventionLayer) {
          mapInstanceRef.current.removeLayer(interventionLayer);
        }
      }
    };
  }, [maskLayer, interventionLayer]);

  // Function to create a vector layer for the mask
  const createMaskVectorLayer = (polygon: Polygon): VectorLayer<VectorSource<Feature<Geometry>>> => {
    const extent = mapInstanceRef.current!.getView().calculateExtent();
    const extentRing = [
      [extent[0], extent[1]],
      [extent[2], extent[1]],
      [extent[2], extent[3]],
      [extent[0], extent[3]],
      [extent[0], extent[1]]
    ];

    const polygonWithHole = new Polygon([extentRing, polygon.getCoordinates()[0]]);

    const source = new VectorSource<Feature<Geometry>>({
      features: [new Feature({
        geometry: polygonWithHole
      })]
    });

    const layer = new VectorLayer<VectorSource<Feature<Geometry>>>({
      source: source,
      style: new Style({
        fill: new Fill({
          color: 'rgba(255, 253, 245, 0.5)'
        }),
        stroke: new Stroke({
          color: 'rgba(0, 0, 255, 1)',  // Blue border
          width: 2
        })
      }),
      zIndex: 3
    });

    return layer;
  };

  // Ensure this function is called when drawing ends
  const handleDrawEnd = useCallback((event: DrawEvent) => {
    const feature = event.feature;
    const geometry = feature.getGeometry();
    
    if (geometry instanceof Polygon) {
      setSelectedArea(geometry);
      
      if (mapInstanceRef.current) {
        if (maskVectorLayer) {
          mapInstanceRef.current.removeLayer(maskVectorLayer);
          setMaskVectorLayer(null);
        }

        const extent = mapInstanceRef.current.getView().calculateExtent();
        const extentRing = [
          [extent[0], extent[1]],
          [extent[2], extent[1]],
          [extent[2], extent[3]],
          [extent[0], extent[3]],
          [extent[0], extent[1]]
        ];

        const polygonWithHole = new Polygon([extentRing, geometry.getCoordinates()[0]]);
        const source = new VectorSource<Feature<Geometry>>({
          features: [new Feature({
            geometry: polygonWithHole
          })]
        });

        const newMaskVectorLayer = new VectorLayer<VectorSource<Feature<Geometry>>>({
          source: source,
          style: new Style({
            fill: new Fill({
              color: 'rgba(255, 253, 245, 0.5)'
            }),
            stroke: new Stroke({
              color: 'rgba(0, 0, 255, 1)',  // Blue border
              width: 2
            })
          }),
          zIndex: 100
        });

        mapInstanceRef.current.addLayer(newMaskVectorLayer);
        setMaskVectorLayer(newMaskVectorLayer);
      }

      setActiveSelection(true);
    }
  }, [mapInstanceRef, maskVectorLayer]);

  // Add this near the other useCallback/function definitions
  const handleMapReady = useCallback((map: Map) => {
    mapInstanceRef.current = map;
  }, []);

  // Add these new states near the other state declarations
  const [sortBand, setSortBand] = useState<number | 'cost' | null>(null);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');
  const [bandSortDirection, setBandSortDirection] = useState<'asc' | 'desc'>('desc');
  const [bandSortType, setBandSortType] = useState<'base' | 'total' | 'intervention'>('total');

  // Add these state declarations near the top with other useState declarations
  const [interventionSort, setInterventionSort] = useState<'name' | 'cost' | 'area' | 'm2_value'>('name');
  const [interventionSortDirection, setInterventionSortDirection] = useState<'asc' | 'desc'>('asc');
  const [selectedM2Value, setSelectedM2Value] = useState<number>(0);

  // Then the getSortedBrushes function can use these state variables
  const getSortedBrushes = useCallback(() => {
    if (!brushes[selectedCategory]) return [];
    
    return [...brushes[selectedCategory]].sort((a, b) => {
      // Skip eraser from sorting
      if (a.name === 'Eraser') return 1;
      if (b.name === 'Eraser') return -1;
      
      if (interventionSort === 'name') {
        return interventionSortDirection === 'asc'
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name);
      } else if (interventionSort === 'cost') {
        return interventionSortDirection === 'asc'
          ? (a.cost || 0) - (b.cost || 0)
          : (b.cost || 0) - (a.cost || 0);
      } else if (interventionSort === 'area') {
        const areaA = canvasBrushAreas[a.name] || 0;
        const areaB = canvasBrushAreas[b.name] || 0;
        return interventionSortDirection === 'asc'
          ? areaA - areaB
          : areaB - areaA;
      } else if (interventionSort === 'm2_value') {
        const valueA = a.values?.[selectedM2Value] || 0;
        const valueB = b.values?.[selectedM2Value] || 0;
        return interventionSortDirection === 'asc'
          ? valueA - valueB
          : valueB - valueA;
      }
      return 0;
    });
  }, [
    brushes, 
    selectedCategory, 
    interventionSort, 
    interventionSortDirection, 
    canvasBrushAreas, 
    selectedM2Value
  ]);

  // Add this state for intervention hover info
  const [hoverIntervention, setHoverIntervention] = useState<{
    name: string;
    values: number[];
  }>({ name: '', values: new Array(bandDescriptions.length).fill(0) });

  // Add new state for loading
  const [isGeneratingReport, setIsGeneratingReport] = useState(false);

  // Add these helper methods inside App component
  const captureBaseMap = async (): Promise<string> => {
    try {
      const mapCanvas = mapInstanceRef.current?.getTargetElement().querySelector('canvas');
      if (!mapCanvas) return '';

      const exportCanvas = document.createElement('canvas');
      const context = exportCanvas.getContext('2d');
      if (!context) return '';

      exportCanvas.width = mapCanvas.width;
      exportCanvas.height = mapCanvas.height;

      context.drawImage(mapCanvas, 0, 0);

      return exportCanvas.toDataURL('image/png');
    } catch (error) {
      console.error('Error capturing base map:', error);
      return '';
    }
  };

  const handleGenerateAndDownloadReport = useCallback(async () => {
    if (!mapInstanceRef.current) return;
    
    // Show payment modal first
    setShowPaymentModal(true);
    setReportPending(true);
  }, [mapInstanceRef]);

  // Add new handler for successful payment
  const handlePaymentSuccess = useCallback(async () => {
    setShowPaymentModal(false);
    setIsGeneratingReport(true);
    
    try {
      const reportGenerator = new ReportGenerator(
        mapInstanceRef.current!,
        bandDescriptions,
        getSortedBrushes(),
        canvasBrushAreas,
        canvasLayers,
        {
          bandTotals: areaPixelSums,
          totalCost,
          selectedArea: totalPixels,
          dateGenerated: new Date(),
          projectName: "Urban Development Analysis",
          location: searchQuery || "Custom Area"
        }
      );

      await reportGenerator.generateReport();
    } catch (error) {
      console.error('Failed to generate report:', error);
    } finally {
      setIsGeneratingReport(false);
      setReportPending(false);
    }
  }, [
    mapInstanceRef,
    bandDescriptions,
    getSortedBrushes,
    canvasBrushAreas,
    canvasLayers,
    areaPixelSums,
    totalCost,
    totalPixels,
    searchQuery
  ]);

  const handleClearSelection = useCallback(() => {
    setSelectedArea(null);
    setActiveSelection(false);
    clearCanvas();
    setTotalCost(0);
    
    const vectorLayer = mapInstanceRef.current?.getLayers().getArray()
      .find(layer => layer instanceof VectorLayer) as VectorLayer<VectorSource<Feature<Geometry>>>;
    
    if (vectorLayer) {
      vectorLayer.getSource()?.clear();
    }
  }, [clearCanvas, setSelectedArea, setActiveSelection, setTotalCost]);

  // Add this near the top of the App component with other state declarations
  const [reportData, setReportData] = useState<ReportData | null>(null);

  // Add near other state declarations
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [reportPending, setReportPending] = useState(false);

  // Add this near the other state declarations at the top of the component
  const [maxCost, setMaxCost] = useState<number>(0);

  // Add this useEffect to calculate maxCost
  useEffect(() => {
    if (!brushes || !selectedCategory) return;
    
    const max = brushes[selectedCategory].reduce((acc, brush) => {
      const brushCost = brush.cost || 0;
      return Math.max(acc, brushCost);
    }, 0);
    
    setMaxCost(max || 1); // Prevent division by zero
  }, [brushes, selectedCategory]);

  const [maxValue, setMaxValue] = useState<number>(100); // Default max value
  
  // Calculate max value whenever area pixel sums or canvas band sums change
  useEffect(() => {
    const maxAreaSum = Math.max(...areaPixelSums);
    const maxCanvasSum = Math.max(...canvasBandSums);
    const maxTotal = Math.max(...areaPixelSums.map((base, i) => base + (canvasBandSums[i] || 0)));
    const newMaxValue = Math.max(maxTotal, 100); // Ensure minimum of 100
    setMaxValue(newMaxValue);
  }, [areaPixelSums, canvasBandSums]);

  // Add these near the other state declarations
  const [measurementSort, setMeasurementSort] = useState<'name' | 'value' | 'impact' | 'base' | 'total'>('value');
  const [measurementSortDirection, setMeasurementSortDirection] = useState<'asc' | 'desc'>('desc');

  // Add these sorting functions
  const getSortedMeasurements = useCallback(() => {
    return [...bandDescriptions].map((description, index) => {
      const baseValue = areaPixelSums[index] || 0;
      const interventionValue = canvasBandSums[index] || 0;
      const totalValue = baseValue + interventionValue;
      const impactValue = interventionValue - baseValue; // Impact is the difference

      return {
        description,
        baseValue,
        interventionValue,
        totalValue,
        impactValue,
        index
      };
    }).sort((a, b) => {
      if (measurementSort === 'name') {
        return measurementSortDirection === 'asc' 
          ? a.description.localeCompare(b.description)
          : b.description.localeCompare(a.description);
      } else if (measurementSort === 'value') {
        return measurementSortDirection === 'asc' 
          ? a.totalValue - b.totalValue
          : b.totalValue - a.totalValue;
      } else if (measurementSort === 'impact') {
        return measurementSortDirection === 'asc'
          ? a.impactValue - b.impactValue
          : b.impactValue - a.impactValue;
      } else if (measurementSort === 'base') {
        return measurementSortDirection === 'asc'
          ? a.baseValue - b.baseValue
          : b.baseValue - a.baseValue;
      } else { // total
        return measurementSortDirection === 'asc'
          ? a.totalValue - b.totalValue
          : b.totalValue - a.totalValue;
      }
    });
  }, [bandDescriptions, areaPixelSums, canvasBandSums, measurementSort, measurementSortDirection]);

  return (
    <div className="app">
      <div className="app-header">
        <h1>UrbanBiome</h1>
        <div className="header-description">
          <p>Identifying vulnerable areas within the urban environment and locating opportunities for green infrastructure</p>
        </div>
      </div>

      <div className="main-content" style={{
        display: 'flex',
        height: 'calc(100vh - 60px)',
        overflow: 'hidden',
        position: 'relative'
      }}>
        {/* Left Panel - Measurements */}
        <div className="left-panel">
          <div className="panel-header" style={{ 
            position: 'sticky', 
            top: 0, 
            zIndex: 1,
            backgroundColor: '#f8f8f8',
            borderBottom: '1px solid #ccc'
          }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span>Measurements</span>
              <div style={{ display: 'flex', gap: '8px' }}>
                <select 
                  value={measurementSort}
                  onChange={(e) => setMeasurementSort(e.target.value as 'name' | 'value' | 'impact' | 'base' | 'total')}
                  style={{
                    padding: '4px',
                    borderRadius: '4px',
                    border: '1px solid #ccc'
                  }}
                >
                  <option value="name">Sort by Name</option>
                  <option value="value">Sort by Current Value</option>
                  <option value="impact">Sort by Impact</option>
                  <option value="base">Sort by Base Value</option>
                  <option value="total">Sort by Total Value</option>
                </select>
                <button
                  onClick={() => setMeasurementSortDirection(prev => prev === 'asc' ? 'desc' : 'asc')}
                  style={{
                    padding: '4px 8px',
                    borderRadius: '4px',
                    border: '1px solid #ccc',
                    background: 'white',
                    cursor: 'pointer'
                  }}
                >
                  {measurementSortDirection === 'asc' ? '↑' : '↓'}
                </button>
              </div>
            </div>
          </div>
          
          <div style={{ 
            overflowY: 'auto',
            overflowX: 'hidden',
            height: 'calc(100% - 56px)', // Adjust based on header height
            paddingTop: '10px'
          }}>
            {getSortedMeasurements().map(({ description, baseValue, interventionValue, totalValue, impactValue, index }) => (
              <div 
                key={index} 
                className={`metric-button ${selectedBands.includes(index + 1) ? 'selected' : ''}`}
                onClick={handleBandSelection(index + 1)}
                style={{
                  backgroundColor: selectedBands.includes(index + 1) ? SELECTED_BLUE_BG : '#ffffff',
                  borderColor: selectedBands.includes(index + 1) ? SELECTED_BLUE : BORDER_COLOR,
                  borderWidth: '2px',
                  borderStyle: 'solid',
                  padding: '10px 15px',
                  margin: '5px 0',
                  width: '100%',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  transform: selectedBands.includes(index + 1) ? 'scale(1.02)' : 'scale(1)',
                  boxShadow: selectedBands.includes(index + 1) ? '0 2px 8px rgba(0,0,0,0.1)' : 'none',
                  transition: 'all 0.2s ease-in-out'
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <span className="metric-title">{bandDescriptions[index]}</span>
                  <InfoTooltip description={description} />
                </div>
                <div className="metric-values" style={{ 
                  color: selectedBands.includes(index + 1) ? '#2196f3cc' : '#666'
                }}>
                  {(activeSelection || areaPixelSums[index] > 0) && (
                    <div className="area-value">
                      <div className="metric-progress" style={{ position: 'relative', height: '20px' }}>
                        <div 
                          className="metric-progress-fill"
                          style={{
                            position: 'absolute',
                            width: `${Math.min(((areaPixelSums[index] || 0) + (canvasBandSums[index] || 0)) / maxValue * 100, 100)}%`,
                            height: '100%',
                            backgroundColor: `rgba(0, 255, 0, 0.5)`,
                            transition: 'width 0.3s ease',
                            zIndex: 1
                          }}
                        />
                        <div 
                          className="metric-progress-fill"
                          style={{
                            position: 'absolute',
                            width: `${Math.min((areaPixelSums[index] || 0) / maxValue * 100, 100)}%`,
                            height: '100%',
                            backgroundColor: `rgba(220, 230, 220, 0.5)`,
                            transition: 'width 0.3s ease',
                            zIndex: 2
                          }}
                        />
                        <div style={{
                          position: 'absolute',
                          left: '4px',
                          top: '50%',
                          transform: 'translateY(-50%)',
                          fontSize: '12px',
                          color: '#000',
                          zIndex: 3,
                          textShadow: '0 0 2px white'
                        }}>
                          {Math.round(areaPixelSums[index] || 0)}
                        </div>
                        <div 
                          style={{
                            position: 'absolute',
                            left: `${Math.min((areaPixelSums[index] || 0) / maxValue * 100, 100)}%`,
                            height: '100%',
                            width: '2px',
                            backgroundColor: '#000',
                            zIndex: 2
                          }}
                        />
                        <div style={{
                          position: 'absolute',
                          right: '4px',
                          top: '50%',
                          transform: 'translateY(-50%)',
                          fontSize: '12px',
                          color: '#000',
                          zIndex: 3,
                          textShadow: '0 0 2px white'
                        }}>
                          {Math.round((areaPixelSums[index] || 0) + (canvasBandSums[index] || 0))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Map Container */}
        <div className="map-container">
          <MapCore
            selectedCategory={selectedCategory}
            selectedBands={selectedBands}
            onMapReady={handleMapReady}
            currentBrush={currentBrush}
            brushSize={brushSize}
            selectedArea={selectedArea}
            drawOnCanvas={drawOnCanvas}
            onHover={(coordinate: number[]) => {
              if (!mapInstanceRef.current) return;
              
              // Get base pixel values
              const event = {
                clientX: coordinate[0],
                clientY: coordinate[1]
              };
              const pixel = mapInstanceRef.current.getEventPixel(event);
              if (pixel) {
                const cogLayer = mapInstanceRef.current.getLayers().getArray()[1] as WebGLTileLayer;
                if (cogLayer && cogLayer instanceof WebGLTileLayer) {
                  try {
                    const data = cogLayer.getData(pixel);
                    if (data) {
                      const pixelData = Array.isArray(data) ? data : Array.from(data as Float32Array);
                      setPixelValues(pixelData);
                    }
                  } catch (error: unknown) {
                    console.error('Error getting pixel data:', error);
                  }
                }
                
                // Get intervention data
                const intervention = getInterventionAtPixel(coordinate);
                if (intervention) {
                  setHoverIntervention({
                    name: intervention.brush?.name || '',
                    values: intervention.values || new Array(bandDescriptions.length).fill(0)
                  });
                } else {
                  setHoverIntervention({ 
                    name: '', 
                    values: new Array(bandDescriptions.length).fill(0) 
                  });
                }
              }
            }}
            setSelectedArea={setSelectedArea}
            setActiveSelection={setActiveSelection}
            isSelectionMode={isSelectionMode}
          >
            {/* children components */}
          </MapCore>
        </div>

        {/* Right Panel - Interventions */}
        <div className="right-panel">
          <div className="panel-header" style={{ 
            position: 'sticky', 
            top: 0, 
            zIndex: 1,
            backgroundColor: '#f8f8f8',
            borderBottom: '1px solid #ccc',
            padding: '10px'
          }}>
            <div style={{ 
              display: 'flex', 
              justifyContent: 'space-between', 
              alignItems: 'center',
              gap: '8px'
            }}>
              <span>Interventions</span>
              <div style={{ 
                display: 'flex', 
                gap: '8px',
                minWidth: 0
              }}>
                <select 
                  value={`${interventionSort}${interventionSort === 'm2_value' ? '_' + selectedM2Value : ''}`}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value.startsWith('m2_value_')) {
                      setInterventionSort('m2_value');
                      setSelectedM2Value(parseInt(value.split('_')[2]));
                    } else {
                      setInterventionSort(value as 'name' | 'cost' | 'area');
                    }
                  }}
                  style={{
                    padding: '4px',
                    borderRadius: '4px',
                    border: '1px solid #ccc',
                    maxWidth: '150px',
                    width: '100%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap'
                  }}
                >
                  <option value="name">Sort by Name</option>
                  <option value="cost">Sort by Cost</option>
                  <option value="area">Sort by Area</option>
                  <optgroup label="Sort by m² Values">
                    {bandDescriptions.map((desc, index) => {
                      const sampleBrush = brushes[selectedCategory]?.[0];
                      if (!sampleBrush?.values?.[index]) return null;
                      
                      let label = desc;
                      if (desc.toLowerCase().includes('temperature')) {
                        label += ' (°C/m²)';
                      } else if (desc.toLowerCase().includes('rainfall')) {
                        label += ' (mm/m²)';
                      } else if (desc.toLowerCase().includes('energy')) {
                        label += ' (kWh/m²)';
                      } else {
                        label += ' (per m²)';
                      }

                      return (
                        <option 
                          key={index} 
                          value={`m2_value_${index}`}
                          style={{
                            maxWidth: '190px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                          }}
                        >
                          Sort by {label}
                        </option>
                      );
                    })}
                  </optgroup>
                </select>
                <button
                  onClick={() => setInterventionSortDirection(prev => prev === 'asc' ? 'desc' : 'asc')}
                  style={{
                    padding: '4px 8px',
                    borderRadius: '4px',
                    border: '1px solid #ccc',
                    background: 'white',
                    cursor: 'pointer',
                    flexShrink: 0
                  }}
                >
                  {interventionSortDirection === 'asc' ? '↑' : '↓'}
                </button>
              </div>
            </div>
          </div>
          
          <div style={{ 
            overflowY: 'auto',
            overflowX: 'hidden',
            height: 'calc(100% - 56px)', // Adjust based on header height
            paddingTop: '10px'
          }}>
            <div style={{ position: 'relative' }}>
              {/* Header message when no area selected */}
              {!activeSelection && (
                <div style={{
                  padding: '8px 15px',
                  fontSize: '12px',
                  color: '#666',
                  fontStyle: 'italic',
                  textAlign: 'right',
                  borderBottom: '1px solid #eee'
                }}>
                  Select an area first
                </div>
              )}

              {/* Intervention buttons list */}
              {getSortedBrushes()?.map((brush: Brush) => {
                // Calculate cumulative cost for this brush
                const brushArea = canvasBrushAreas[brush.name] || 0;
                const totalBrushCost = brushArea * (brush.cost || 0);
                
                // Get the selected band value if sorting by m2_value
                const selectedBandValue = interventionSort === 'm2_value' && brush.values 
                  ? brush.values[selectedM2Value]
                  : null;

                return (
                  <div 
                    key={brush.name}
                    onClick={() => activeSelection ? selectBrush(brush) : null}
                    className={`intervention-button ${!activeSelection ? 'disabled' : ''}`}
                    style={{
                      backgroundColor: currentBrush?.name === brush.name ? SELECTED_BLUE_BG : '#ffffff',
                      borderColor: currentBrush?.name === brush.name ? SELECTED_BLUE : BORDER_COLOR,
                      borderWidth: '2px',
                      borderStyle: 'solid',
                      padding: '10px 15px'
                    }}
                  >
                    <div className="title-section" style={{ 
                      display: 'flex', 
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100%',
                    }}>
                      <span>{brush.name}</span>
                      <InfoTooltip description={brush.description} />
                    </div>

                    {/* Only show progress bars when area is selected */}
                    {activeSelection && selectedBandValue !== null && (
                      <div className="progress-bars">
                        {/* ... progress bars code ... */}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      {/* Footer with improved layout */}
      <div style={{
        padding: '10px',
        borderTop: '1px solid #ccc',
        backgroundColor: '#f8f8f8',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '16px'
      }}>
        {/* Left section - Search */}
        <div className="search-container" style={{ 
          width: '300px',
          margin: '0'
        }}>
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                search(searchQuery);
              }
            }}
            placeholder="Search for a location..."
            style={{
              width: '100%',
              padding: '8px 12px',
              borderRadius: '4px',
              border: `1px solid ${BORDER_COLOR}`,
              fontSize: '14px'
            }}
          />
        </div>

        {/* Center section - Area Selection */}
        <div style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
          <button
            onClick={() => activeSelection ? clearSelection() : toggleSelectionMode()}
            style={{
              padding: '8px 16px',
              borderRadius: '4px',
              border: `1px solid ${BORDER_COLOR}`,
              backgroundColor: isSelectionMode ? SELECTED_BLUE : 'white',
              color: isSelectionMode ? 'white' : SELECTED_BLUE,
              cursor: 'pointer',
              transition: 'all 0.2s ease-in-out',
              fontWeight: '500',
              fontSize: '14px',
              whiteSpace: 'nowrap'
            }}
          >
            {isSelectionMode ? 'Drawing Area...' : 
             activeSelection ? 'Clear Selection' : 'Draw Selected Area'}
          </button>
        </div>

        {/* Right section - Report Generation */}
        <div style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
          <button
            onClick={handleGenerateAndDownloadReport}
            disabled={isGeneratingReport || !selectedArea || reportPending}
            style={{
              padding: '8px 16px',
              borderRadius: '4px',
              border: '1px solid',
              borderColor: (isGeneratingReport || !selectedArea || reportPending) ? '#ccc' : SELECTED_BLUE,
              backgroundColor: (isGeneratingReport || !selectedArea || reportPending) ? '#f5f5f5' : SELECTED_BLUE,
              color: (isGeneratingReport || !selectedArea || reportPending) ? '#666' : 'white',
              cursor: (isGeneratingReport || !selectedArea || reportPending) ? 'not-allowed' : 'pointer',
              transition: 'all 0.2s ease-in-out',
              fontWeight: '500',
              fontSize: '14px',
              whiteSpace: 'nowrap',
              display: 'flex',
              alignItems: 'center',
              gap: '8px'
            }}
          >
            {isGeneratingReport ? (
              <>
                <span className="loading-spinner"></span>
                Generating PDF...
              </>
            ) : reportPending ? (
              'Processing Payment...'
            ) : (
              'Generate Report (£1)'
            )}
          </button>
        </div>
      </div>

      {/* Add this CSS for the loading spinner */}
      <style>
        {`
          .loading-spinner {
            width: 16px;
            height: 16px;
            border: 2px solid #ffffff;
            border-top: 2px solid transparent;
            border-radius: 50%;
            animation: spin 1s linear infinite;
          }

          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
      </style>

      {/* Payment Modal */}
      {showPaymentModal && (
        <PaymentModal
          amount={1}
          onSuccess={handlePaymentSuccess}
          onCancel={() => {
            setShowPaymentModal(false);
            setReportPending(false);
          }}
        />
      )}
    </div>
  );
}

export default App;  
