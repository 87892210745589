import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

// Global state to track active tooltip
let activeTooltipId: string | null = null;
const tooltipSubscribers = new Set<() => void>();

const notifySubscribers = () => {
  tooltipSubscribers.forEach(callback => callback());
};

interface InfoTooltipProps {
  description: string;
}

export const InfoTooltip: React.FC<InfoTooltipProps> = ({ description }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [tooltipId] = useState(() => Math.random().toString(36).substr(2, 9));
  const [position, setPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    const callback = () => {
      if (activeTooltipId !== tooltipId) {
        setIsVisible(false);
      }
    };
    tooltipSubscribers.add(callback);
    return () => {
      tooltipSubscribers.delete(callback);
    };
  }, [tooltipId]);

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    
    const rect = e.currentTarget.getBoundingClientRect();
    setPosition({
      top: rect.top - 10,
      left: rect.left + rect.width / 2
    });

    if (!isVisible) {
      activeTooltipId = tooltipId;
      notifySubscribers();
      setIsVisible(true);
    } else {
      activeTooltipId = null;
      setIsVisible(false);
    }
  };

  useEffect(() => {
    const handleDocumentClick = () => {
      if (isVisible) {
        activeTooltipId = null;
        notifySubscribers();
        setIsVisible(false);
      }
    };

    if (isVisible) {
      document.addEventListener('click', handleDocumentClick);
    }

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [isVisible]);

  return (
    <>
      <div 
        onClick={handleClick}
        style={{ 
          display: 'inline-block',
          marginLeft: '6px',
          cursor: 'pointer'
        }}
      >
        <div style={{
          width: '14px',
          height: '14px',
          borderRadius: '50%',
          backgroundColor: '#f5f5f5',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '11px',
          fontWeight: 500,
          color: '#666',
          border: '1px solid #ddd',
          userSelect: 'none'
        }}>
          i
        </div>
      </div>

      {isVisible && ReactDOM.createPortal(
        <div style={{
          position: 'fixed',
          top: `${position.top}px`,
          left: `${position.left}px`,
          transform: 'translate(-50%, -100%)',
          backgroundColor: 'white',
          padding: '8px 12px',
          borderRadius: '4px',
          boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
          border: '1px solid #ddd',
          zIndex: 1000,
          width: '220px',
          fontSize: '12px',
          color: '#333',
          textAlign: 'left',
          whiteSpace: 'normal',
          lineHeight: '1.4'
        }}>
          {description}
          <div style={{
            position: 'absolute',
            bottom: '-6px',
            left: '50%',
            marginLeft: '-6px',
            width: '12px',
            height: '12px',
            transform: 'rotate(45deg)',
            backgroundColor: 'white',
            border: '1px solid #ddd',
            borderTop: 'none',
            borderLeft: 'none'
          }} />
        </div>,
        document.body
      )}
    </>
  );
}; 